import ApiInstance from "../../axios/api.auth";

const findAll = async(page, size, search) => {
  return await ApiInstance.get(`/admin/banners?page=${page}&size=${size}&search=${search}`);
};
const findOne = async (id) => {
  return await ApiInstance.get(`/admin/banners/${id}`);
};
const create = async(data) => {
  return await ApiInstance.post("/admin/banners", data);
};
const destroy = async(id) => {
  return await ApiInstance.delete(`/admin/banners/${id}`);
};

const update = async(id, data) => {
  return await ApiInstance.put(`/admin/banners/${id}`, data);
};

const deactivate = async(id) => {
    return await ApiInstance.patch(`admin/banners/${id}/deactivate`);
  };
  
const BannerService = {
  findAll,
  findOne,
  create,
  update,
  destroy,
  deactivate
};

export default BannerService;
