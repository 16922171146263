import React from 'react';

const NoConnectionPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 text-center p-6">
      {/* SVG Icon for No Connection */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-16 h-16 text-red-600 mb-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        strokeWidth="2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3 12l18 0M18 12a9 9 0 1 0-9 9m0 0a9 9 0 1 1-9-9"
        />
      </svg>

      <h1 className="text-3xl font-semibold text-red-400 mb-3">No Internet Connection</h1>
      <p className="text-base text-gray-500 mb-5">Please check your network settings and try again.</p>
      
      <button
        onClick={() => window.location.reload()}
        className="px-5 py-2.5 bg-blue-500 text-white font-medium rounded-md shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200"
      >
        Retry
      </button>
    </div>
  );
};

export default NoConnectionPage;
