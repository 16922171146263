import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import RenderRazorpay from "../../../components/payments/RenderRazorpay";
import { fetchOrder } from "../../../features/order.slice";
import PaymentService from "../../../services/user/payment.services";

const CheckoutPage = () => {
  const [displayRazorpay, setDisplayRazorpay] = useState(false);

  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const orderId = location.state?.id;

  const { item, status, error } = useSelector((state) => state.order);
  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: null,
    amount: null,
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (orderId > 0) {
      dispatch(fetchOrder(orderId));
    }
  }, [dispatch, orderId]);

  const handleCreateOrder = async (amount, orderId) => {
    try {
      const data = {
        amount: amount,
        orderId: orderId,
      }
      const response = await PaymentService.paymentInitiate(data);
      const responseData = response.data.data;
      console.log(responseData)

      if (responseData && responseData.id) {
        setOrderDetails({
          orderId: responseData.id,
          currency: responseData.currency,
          amount: responseData.amount,
        });
        setDisplayRazorpay(true);
      }
    } catch (error) {
      console.error('Error creating Razorpay order:', error);
    }
  };

  if (orderId && item)
    return (
      <div className="page-wrapper ms-0 bg-gray-50">
        <div className="container mx-auto px-4 py-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <h4 className="text-2xl font-semibold text-gray-800 mb-6">Checkout</h4>

            {status === 'loading' && <p>Loading addresses...</p>}


            {status === 'failed' && <p>{error || 'Failed to load addresses.'}</p>}
            {status === 'succeeded' && (
              <>
                {item && item.address_master && item.orderNumber ? (
                  <div className="lg:col-span-2">
                    <div className="bg-white shadow-lg rounded-lg p-6 mb-6">

                      <div className="totalitem mb-2">
                        <p className="inline-block px-3 py-1 text-sm font-semibold text-white bg-gray-400 rounded-full hover:bg-gray-500 transition-colors duration-300">
                          <small>ORDER :<span className="font-semibold  text-white"> #{item.orderNumber}</span></small>
                        </p>
                        <div className=" text-gray-600 uppercase">
                          <small>Order Placed :<span className="font-semibold  text-blue-400"> {new Date(item.createdAt).toLocaleDateString()}</span></small>
                        </div>
                      </div>
                      <div className="mt-6">
                        <div className="flex flex-col sm:flex-row items-start border-b pb-2 mb-3">
                          <div className="flex-grow overflow-hidden">
                            <h6 className="font-semibold text-lg text-gray-800 truncate mt-2">
                              Shipping Address
                            </h6>
                            <div className="text-yellow-400 mt-2">
                              <p className="mt-2 text-gray-600"><span className="font-bold">{item.address_master.fullName}</span></p>
                              <p className="mt-1 text-gray-600"><span className="font-medium">{item.address_master.phone}</span></p>
                              <p className="text-gray-600">{item.address_master.houseNo}{item.address_master.landmark}</p>
                              <p className="text-gray-600">{item.address_master.city}{item.address_master.district}{item.address_master.state}</p>
                              <p className="text-gray-600">{item.address_master.pinCode} IN</p>
                            </div>
                          </div>
                          <div className="flex-grow overflow-hidden">
                            <h6 className="font-semibold text-lg text-gray-800 truncate mt-2">
                              Order Status
                            </h6>
                            <p className="mt-2 text-gray-600">Order Status : <span className="font-medium uppercase text-blue-400">{item.status}</span></p>
                            <p className="mt-2 text-gray-600">Payment Status : <span className="font-medium uppercase text-green-400">{item.paymentStatus}</span></p>
                            <p className="mt-2 text-gray-600">Payment Mode : <span className="font-medium uppercase text-orange-400">{item.paymentMethod}</span></p>
                          </div>
                          <div className="flex-grow overflow-hidden">
                            <h6 className="font-semibold text-lg text-gray-800 truncate mt-2">
                              Expected Delivery
                            </h6>
                            <div className="text-yellow-400 mt-2">
                              <p className="mt-2 text-red-500"><span className="font-medium"> {item.expectedDelivery}</span></p>
                            </div>
                          </div>
                        </div>
                        {item.order_items.length > 0 ? (
                          item.order_items.map((itm, i) => (
                            <div className="flex flex-col sm:flex-row items-start border-b pb-2 mb-3 divide-y divide-dashed" key={i}>
                              <div className="mr-6 mb-4 sm:mb-0">
                                <img
                                  crossOrigin="anonymous"
                                  src={`${BASE_URL}${itm.productSlug}?t=${new Date().getTime()}`}
                                  alt='Product img'
                                  className="rounded-md w-24 h-24 object-cover"
                                />
                              </div>
                              <div className="flex-grow overflow-hidden">
                                <h6 className="font-semibold text-lg text-gray-800 truncate">
                                  {itm.productName}
                                </h6>
                                <p className="mt-2 text-gray-600">Rate : <span className="font-medium">₹{itm.price} /{itm.unit}</span></p>
                                <p className="mt-2 text-gray-600">Quantity : <span className="font-medium">{itm.quantity}</span></p>
                                <p className="mt-2 text-gray-600">Sub Total : <span className="font-medium">₹{parseFloat((itm.subTotalPrice).toFixed(2))}</span></p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="flex-shrink-0" >No found product.</div>
                        )}

                      </div>
                      <h6 className="font-medium text-lg text-gray-600 truncate mt-2">
                        Total Amount:<span className="text-lg font-extrabold text-gray-600"> ₹{parseFloat((item.totalPrice).toFixed(2))}</span>
                      </h6>
                      <div className="flex space-x-4 justify-center mt-8">
                        {item.paymentMethod === 'ONLINE' && (
                          <button onClick={() => handleCreateOrder(`${parseFloat((item.totalPrice).toFixed(2))}`, `${item.orderNumber}`)} className="px-6 uppercase py-2 text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 font-semibold rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105">
                            Pay Now
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (<div className="flex-shrink-0" >No found data.</div>)}
              </>
            )}
          </div>
        </div>


        {displayRazorpay && (
          <RenderRazorpay
            amount={orderDetails.amount}
            currency={orderDetails.currency}
            orderId={orderDetails.orderId}
            selectedAddress={item.address_master}
          />

        )}
      </div>
    );
};

export default CheckoutPage;
