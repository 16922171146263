import React, { useState, useEffect, useCallback} from "react";
import ExpanseService from "../../../services/admin/expanse.services";
import DataTable from "../../../components/Datatable/DataTable"
import { Link } from "react-router-dom";

const columns = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'expense_date', headerName: 'Expense Date', width: 200 },
  { field: 'amount', headerName: 'Amount', width: 150 },
  { field: 'reference_no', headerName: 'Reference No', width: 250 },
  { field: 'expense_for', headerName: 'Expense For', width: 250 },
  { field: 'description', headerName: 'Description', width: 250 },
];

const Expanse = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const fetchData = useCallback(async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const response = await ExpanseService.findAll(page, pageSize, searchTerm);
      console.log(response)
      setData(response.data.data.rows);
      setTotalCount(response.data.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
  }, [paginationModel, fetchData, searchTerm]);

  return (
   <>
   <div className="content">
  <div className="page-header">
    <div className="page-title">
      <h4>Expenses LIST </h4>
      <h6>Manage your purchases</h6>
    </div>
    <div className="page-btn">
      <Link to={"/expanse/add"} className="btn btn-added"><img src="assets/img/icons/plus.svg" className="me-2" alt="img" />Add New Expense</Link>
    </div>
  </div>
  <div className="card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <Link className="btn btn-searchset"><img src="assets/img/icons/search-white.svg" alt="img" /></Link>
                  <div id="DataTables_Table_0_filter" className="dataTables_filter"><label>
                    <input type="search" value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)} className="form-control form-control-sm" placeholder="Search..." /></label></div></div>
              </div>
            </div>
            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={data}
                loading={loading}
                totalCount={totalCount}
                paginationModel={paginationModel}
                onPaginationModelChange={(model) => setPaginationModel(model)}
              />
            </div>
          </div>
        </div>
</div>

   </>
  )
}

export default Expanse
