import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const orderId = location.state?.id;
    const handleContinueShopping = () => navigate("/");
    
    if (orderId)
        return (
            <div className="page-wrapper ms-0">

                <div className="flex flex-col items-center justify-center min-h-screen bg-green-50 py-10">
                    <div className="text-center p-6 bg-white rounded-lg shadow-md max-w-lg w-full">
                        <h2 className="text-3xl font-semibold text-green-600">Order Placed Successful!</h2>
                        <div className="mt-4">
                            <p className="mt-2 text-gray-600 uppercase">OrderId : <span className="font-medium uppercase text-orange-400">{orderId}</span></p>

                        </div>
                        <p className="mt-4 text-lg text-gray-700">
                            Your order has been successfully placed. You will receive an email confirmation soon.
                        </p>
                        <div className="mt-6">
                            <button
                                className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                                onClick={handleContinueShopping}
                            >
                                Continue Shopping
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
};

export default PaymentSuccess;
