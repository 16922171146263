import ApiInstance from "../../axios/api.auth";

const findAll = async(page, size, search) => {
  return await ApiInstance.get(`/admin/orders?page=${page}&size=${size}&search=${search}`);
};


const OrderService = {
  findAll,
};

export default OrderService;
