import axios from 'axios';
import { BASE_URL } from '../utils/utils';

// Login API request
const login = async (mobile) => {
  try {
    const response = await axios.post(`${BASE_URL}auth/login`, { mobile });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Login failed');
  }
};

// OTP Verify API request
const otpVerify = async (mobile, otp) => {
  try {
    const response = await axios.post(`${BASE_URL}auth/otpVerify`, { mobile, otp });
    const _response = response.data;
    return _response?.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'OTP verification failed');
  }
};







const AuthService = {
  login,
  otpVerify
};

export default AuthService;
