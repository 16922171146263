import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectCurrentUser } from "../features/auth.slice";

const DashboardRedirect = () => {
    const user = useSelector(selectCurrentUser);

    // // If user is not authenticated, redirect to login page
    if (user == null) {
        return <Navigate to="/login" />;
    }
    // Redirect based on user role
    if (user.role === 'ADMIN') {
        return <Navigate to="/admin" />;
    }
    else if (user.role === 'USER') {
        return <Navigate to="/user" />;
    }
    return <Navigate to="/no-access" />;
};

export default DashboardRedirect;
