import ApiInstance from "../../axios/api.auth";

const getProfile = () => {
  return ApiInstance.get("admin/profile");
};

const AdminService = {
  getProfile,
};

export default AdminService;

// publicAxiosInstance.get('/data')
//   .then(response => {
//     console.log(response.data);
//   })
//   .catch(error => {
//     console.error('Error fetching data:', error);
//   });
