import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white text-gray-700 text-xs py-2">
      <div className="container mx-auto text-center">
        <p>&copy; 2024 <Link to={""}>Nesting Probe</Link> All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
