import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from 'react-router-dom';
import { isEmpty } from "validator";
import ProductService from "../../../services/admin/product.services";
import { toast } from "react-toastify";

const ProductsDetails = () => {
    const params = useParams();
    const id = params.id;
    const [productDetail, setProductDetail] = useState([]);
    const BASE_URL = process.env.REACT_APP_API_URL;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchProductData = useCallback(async () => {
        try {
            if ((!isEmpty(id)) && (id > 0)) {
                const response = await ProductService.findOne(id);
                console.log(response, "fetchProductData")
                if (response.status) {
                    const data = response.data.data;
                    setProductDetail({
                        id: data.id,
                        productName: data.productName,
                        category: data.categories_master.categoriesName,
                        subCategory: data.sub_category.subCategoriesName,
                        brand: data.brand_master.brandName,
                        unit: data.unit_master.unitType,
                        sku: data.sku,
                        minStockLimit: data.minStockLimit,
                        stock: data.stock,
                        price: `₹${data.price.toFixed(2)}`,
                        tax: data.tax_master.taxName + " " + data.tax_master.taxPars+" %",
                        discount: data.discount_master.percentage,
                        description: data.description,
                        createdAt: new Date(data.createdAt).toLocaleDateString(),
                        status: (data.status ? 'Active' : 'Inactive'),
                        attachmentName: data.attachmentName
                    });
                } else {
                    toast.error("Failed to load product data.");
                }
            }

        } catch (error) {
            console.error("Error fetching product data:", error);
            toast.error("Failed to load product data.");
        }
    });

    useEffect(() => {
        fetchProductData();
    }, []);

    return (
        <div className="content">
            <div className="page-header">
                <div className="page-title">
                    <h4>Product Details</h4>
                    <h6>Full details of a product</h6>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-8 col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="productdetails">
                                <ul className="product-bar">
                                    <li>
                                        <h4>Product Name</h4>
                                        <h6>{productDetail.productName}</h6>
                                    </li>
                                    <li>
                                        <h4>Category</h4>
                                        <h6>{productDetail.category}</h6>
                                    </li>
                                    <li>
                                        <h4>Sub Category</h4>
                                        <h6>{productDetail.subCategory || 'None'}</h6>
                                    </li>
                                    <li>
                                        <h4>Brand</h4>
                                        <h6>{productDetail.brand || 'None'}</h6>
                                    </li>
                                    <li>
                                        <h4>SKU</h4>
                                        <h6>{productDetail.sku}</h6>
                                    </li>
                                    <li>
                                        <h4>Min Stock Limit</h4>
                                        <h6>{productDetail.minStockLimit}</h6>
                                    </li>
                                    <li>
                                        <h4>Stock</h4>
                                        <h6>{productDetail.stock}</h6>
                                    </li>
                                    <li>
                                        <h4>Unit</h4>
                                        <h6>{productDetail.unit}</h6>
                                    </li>
                                    <li>
                                        <h4>Price</h4>
                                        <h6>{productDetail.price} /{productDetail.unit}</h6>
                                    </li>
                                    <li>
                                        <h4>Created At</h4>
                                        <h6>{productDetail.createdAt}</h6>
                                    </li>
                                    <li>
                                        <h4>Tax (%)</h4>
                                        <h6>{productDetail.tax}</h6>
                                    </li>
                                    <li>
                                        <h4>Discount (%)</h4>
                                        <h6>{productDetail.discount}</h6>
                                    </li>
                                    <li>
                                        <h4>Status</h4>
                                        <h6>{productDetail.status}</h6>
                                    </li>
                                    <li>
                                        <h4>Description</h4>
                                        <h6>{productDetail.description}</h6>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-12 mt-3">
                                <Link to={"/admin/products"} className="btn btn-cancel" type="reset">
                                    Cancel
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="flex flex-col justify-center items-center text-center">
                                <img
                                    src={`${BASE_URL}${productDetail.attachmentName}`}
                                    alt=""
                                    crossOrigin="anonymous"
                                    className="mb-2" 
                                />
                                <h4 className="font-semibold">{productDetail.attachmentName}</h4>
                                <h6 className="text-gray-500">581kb</h6>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ProductsDetails;
