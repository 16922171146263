import React, { useEffect } from 'react';
import { toast } from 'react-toastify'; // Import toast from react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import default styles from react-toastify
import { useSelector } from 'react-redux'; // For accessing Redux state

const ErrorNotification = () => {
  const errorMessage = useSelector((state) => state.error.errorMessage); // Access the error from Redux store

  useEffect(() => {
    if (errorMessage) {
      // Show the error toast notification with Tailwind styling
      toast.error(
        <div className="flex items-center space-x-3 uppercase">     
          
          {/* Error Message */}
          <span className="text-gray font-semibold">{errorMessage}</span>
        </div>,
    
      );
    }
  }, [errorMessage]); // Re-run the effect when the errorMessage changes

  return null; // This component doesn't render anything itself, it just triggers the toast notifications
};

export default ErrorNotification;
