import ApiInstance from "../../axios/api.auth";



const validateCoupons = async(code) => {
  return await ApiInstance.get(`/user/coupons/validate/${code}`);
};
const findAllCoupons = async(id) => {
  return await ApiInstance.get(`/user/coupons/`);
};


const CouponServices = {
    validateCoupons,
    findAllCoupons
};

export default CouponServices;

