import ApiInstance from "../../axios/api.auth";

const createAndUpdate = async (data) => {
    return await ApiInstance.post("admin/attachments/", data,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
};

const destroy = async (data) => {
    return await ApiInstance.post("admin/attachments/delete",data);
};

const AttachmentService = {
    destroy,
    createAndUpdate
};

export default AttachmentService;
