import React from "react";
import { useNavigate } from "react-router-dom";

const OrderTracking = () => {
  const navigate = useNavigate();


  const order = {
    orderId: "123456789",
    orderDate: "2024-11-25",
    status: "Shipped", 
    trackingNumber: "1Z9999W99999999999",
    trackingLink: "https://www.fedex.com/fedextrack/?trknbr=1Z9999W99999999999",
  };

  const statusTimeline = [
    { date: "2024-11-25", status: "Order placed" },
    { date: "2024-11-26", status: "Shipped" },
    { date: "2024-11-27", status: "Out for Delivery" },
    { date: "2024-11-28", status: "Delivered" },
  ];


  const statusOrder = [
    "Order placed",
    "Shipped",
    "Out for Delivery",
    "Delivered",
  ];
  const currentStatusIndex = statusOrder.indexOf(order.status);
  const progress = ((currentStatusIndex + 1) / statusOrder.length) * 100;

  return (
    <div className="page-wrapper ms-0">
      <div className="content">
        <div className="bg-gray-50 text-gray-900">
      
          <section className="py-16 px-4 text-center">
            <h1 className="text-3xl sm:text-4xl font-bold mb-4">Track Your Order</h1>
            <p className="text-lg mb-6">
              Stay updated with the latest status of your order.
            </p>
          </section>

        
          <section className="bg-white shadow-lg rounded-lg p-6 max-w-3xl mx-auto mb-10">
            <div className="flex justify-between items-center mb-6">
              <div>
                <h2 className="text-xl font-semibold">Order ID: {order.orderId}</h2>
                <p className="text-gray-600">Order Date: {order.orderDate}</p>
              </div>
              <div
                className={`text-sm font-semibold px-4 py-1 rounded-full ${order.status === "Shipped"
                    ? "bg-blue-500 text-white"
                    : order.status === "Pending"
                      ? "bg-yellow-500 text-white"
                      : order.status === "Delivered"
                        ? "bg-green-500 text-white"
                        : "bg-gray-500 text-white"
                  }`}
              >
                {order.status}
              </div>
            </div>

      
            {order.trackingNumber && (
              <div className="mt-4">
                <p className="text-gray-600">Tracking Number: {order.trackingNumber}</p>
                <a
                  href={order.trackingLink}
                  className="text-blue-600 hover:underline mt-2 block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Track your package
                </a>
              </div>
            )}
          </section>

     
          <section className="max-w-3xl mx-auto mb-10">
            <h3 className="text-2xl font-semibold mb-6 text-center">Order Progress</h3>
            <div className="relative w-full h-2 bg-gray-300 rounded-full">
              <div
                className="h-full bg-blue-500 rounded-full transition-all duration-300"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <div className="flex justify-between mt-2">
              <span className="text-sm text-gray-600">Order placed</span>
              <span className="text-sm text-gray-600">Shipped</span>
              <span className="text-sm text-gray-600">Out for Delivery</span>
              <span className="text-sm text-gray-600">Delivered</span>
            </div>
          </section>

       
          <section className="max-w-3xl mx-auto mb-10">
            <h3 className="text-2xl font-semibold mb-6 text-center">Order Status Timeline</h3>
            <div className="relative">
              <div className="absolute left-1/2 w-px h-full bg-gray-300"></div>
              <div className="space-y-6">
                {statusTimeline.map((item, index) => (
                  <div
                    key={index}
                    className={`flex items-start ${index % 2 === 0 ? "ml-6" : "mr-6"}`}
                  >
                    <div className="relative">
                      <div
                        className={`w-4 h-4 rounded-full ${item.status === "Delivered"
                            ? "bg-green-500"
                            : item.status === "Shipped"
                              ? "bg-blue-500"
                              : item.status === "Out for Delivery"
                                ? "bg-yellow-500"
                                : "bg-gray-500"
                          }`}
                      ></div>
                      <div
                        className={`absolute top-0 left-1/2 transform -translate-x-1/2 w-1 w-px h-4 ${item.status === "Delivered"
                            ? "bg-green-500"
                            : item.status === "Shipped"
                              ? "bg-blue-500"
                              : item.status === "Out for Delivery"
                                ? "bg-yellow-500"
                                : "bg-gray-500"
                          }`}
                      ></div>
                    </div>
                    <div className="ml-4">
                      <p className="text-sm text-gray-600">{item.date}</p>
                      <p className="text-md text-gray-800 font-medium">{item.status}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

       
          <section className="text-center">
            <button
              onClick={() => navigate("/orders")}
              className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300"
            >
              Back to Orders
            </button>
          </section>
        </div>
      </div></div>
  );
};

export default OrderTracking;
