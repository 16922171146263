import Category from "../category";
import Carousel from "../carousel";
import Marquees from "../marquee";
import Products from "../products";

const DashboardPage = () => {
    return (
        <div className="page-wrapper ms-0">
            <div className="content">
                <Marquees />
                <Carousel />
            </div>
            <div className="content">
                <Category />
            </div>
            <div className="content">
                <Products />
            </div>
        </div>
    );
};

export default DashboardPage;
