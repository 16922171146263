import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ProductsService from '../services/user/products.services'; // Ensure this path is correct

// Async thunk to fetch products
export const fetchCategories = createAsyncThunk(
    'categories/fetchCategories',
    async () => {
        try {
            const response = await ProductsService.findAllCategories();
            // console.log("Fetched Product Categories: ", response.data);  // Logging to verify the data
            return response.data.data;  // Ensure the data is in response.data.data
        } catch (error) {
            console.error('Error fetching product categories: ', error);
            throw Error('Failed to fetch product categories');  // Throw error to trigger the rejected case
        }
    }
);

const categorySlice = createSlice({
    name: 'categories',
    initialState: {
        categories: [], // This will hold the categories
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.status = 'loading';  // Set status to loading while fetching
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.status = 'succeeded';  // Set status to succeeded after successful fetch
                state.categories = action.payload;  // Store fetched products in state
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.status = 'failed';  // Set status to failed if the fetch fails
                state.error = action.error.message;  // Store the error message
            });
    },
});

export default categorySlice.reducer;
