/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import BrandService from "../../../services/admin/brand.services";
import UnitService from "../../../services/admin/unit.services";
import TaxService from "../../../services/admin/tax.services";
import DiscountService from "../../../services/admin/discount.services";
import ProductService from "../../../services/admin/product.services";
import { toast } from "react-toastify";
import CategoriesService from "../../../services/admin/categories.services";
import SubCategoriesService from "../../../services/admin/subCategories.services";
import { useParams, useNavigate, Link } from "react-router-dom";
import { isEmpty } from "validator";
import FileUpload from "../../../components/FileUpload/FileUpload";


const initialValues = {
  id: 0,
  productName: '',
  categoryId: '',
  subCategoriesId: '',
  brandId: '',
  unitId: '',
  sku: '',
  minStockLimit: '',
  stock: '',
  price: '',
  taxId: '',
  discountId: '',
  status: '',
  description: '',
  attachmentId: '',
  attachmentName: ''

};

const ProductsForm = () => {
  const params = useParams();
  const id = params.id;
  const [brand, setBrand] = useState([]);
  const [unit, setUnit] = useState([]);
  const [tax, setTax] = useState([]);
  const [discount, setDiscount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState('');
  const [data, setData] = useState(initialValues);
  const BASE_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();


  const handleCategoriesChange = async (event) => {
    const selectedCategories = event.target.value;
    setSelectedCategories(selectedCategories);
    setSelectedSubCategories(''); 
    if (selectedCategories) {
      try {
        const response = await SubCategoriesService.findAllCategories(selectedCategories);
        setSubCategories(response.data.data);
      } catch (error) {
        console.error('Error fetching districts:', error);
      }
    } else {
      setSubCategories([]); 
    }
  };
  const handleSubCategoriesChange = (event) => {
    const selectedSubCategories = event.target.value;
    setSelectedSubCategories(selectedSubCategories);
  };
 
  const validationSchema = Yup.object().shape({
    productName: Yup.string().required('Product Name is required'),
    categoryId: Yup.number().required('Category is required'),
    subCategoriesId: Yup.number().required('Sub Category is required'),
    brandId: Yup.string().required('Brand is required'),
    unitId: Yup.string().required('Unit is required'),
    sku: Yup.string().required('SKU is required'),
    minStockLimit: Yup.number().required('Minimum Stock Limit is required').positive().integer(),
    stock: Yup.number().required('Stock is required').positive().integer(),
    price: Yup.number().required('Price is required').positive(),
    taxId: Yup.string().required('Tax is required'),
    discountId: Yup.string().required('Discount Type is required'),
    status: Yup.string().optional(),
    description: Yup.string().optional(),
    attachmentName: Yup.string().required("A file is required")
  });

  const handleSubmit = async (values, { resetForm }) => {
    console.log(values, "values");
    const data = {
      productName: values.productName,
      categoryId: Number(values.categoryId),
      subCategoriesId: Number(values.subCategoriesId),
      brandId: Number(values.brandId),
      unitId: Number(values.unitId),
      sku: values.sku,
      minStockLimit: Number(values.minStockLimit),
      stock: Number(values.stock),
      price: Number(values.price),
      taxId: Number(values.taxId),
      discountId: Number(values.discountId),
      //status: Boolean(values.status),
      description: values.description,
      attachmentId: values.attachmentId,
      attachmentName: values.attachmentName,
    };
    setLoading(true);
    try {
      if ((!isEmpty(id)) && (id > 0)) {
        const response = await ProductService.update(id, data);
        console.log(response, "####")
        if (response.data) {
          navigate("/admin/products");
          toast.success("product updated successfully!");
        } else {
          toast.error(response.message);
        }
      } else {
        const response = await ProductService.create(data);
        if (response.data) {
          navigate("/admin/products");
          toast.success("product added successfully!");
          resetForm();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.error("Error submitting product:", error);
      toast.error(id ? "Failed to update product." : "Failed to create product.");
    } finally {
      setLoading(false);
    }
  };

  const fetchBrand = useCallback(async () => {
    setLoading(true);
    try {
      const response = await BrandService.findAllBrand();
      // console.log(response)
      const result = response.data.data.rows;
      console.log(result)
      if (Array.isArray(result)) {
        setBrand(result);
      } else {
        toast.error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching brand:", error);
      toast.error("Failed to fetch brand");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchCategories = useCallback(async () => {
    setLoading(true);
    try {
      const response = await CategoriesService.findAllCategories();
      // console.log(response)
      const result = response.data.data.rows;
      console.log(result)
      if (Array.isArray(result)) {
        setCategories(result);
      } else {
        toast.error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching category:", error);
      toast.error("Failed to fetch category");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchUnit = useCallback(async () => {
    setLoading(true);
    try {
      const response = await UnitService.findAllUnit();
      // console.log(response)
      const result = response.data.data.rows;
      console.log(result)
      if (Array.isArray(result)) {
        setUnit(result);
      } else {
        toast.error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching unit:", error);
      toast.error("Failed to fetch unit");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchTax = useCallback(async () => {
    setLoading(true);
    try {
      const response = await TaxService.findAllTax();
      // console.log(response)
      const result = response.data.data.rows;
      console.log(result)
      if (Array.isArray(result)) {
        setTax(result);
      } else {
        toast.error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching tax:", error);
      toast.error("Failed to fetch tax");
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchDiscount = useCallback(async () => {
    setLoading(true);
    try {
      const response = await DiscountService.findAllDiscount();
      // console.log(response)
      const result = response.data.data.rows;
      console.log(result)
      if (Array.isArray(result)) {
        setDiscount(result);
      } else {
        toast.error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching discount:", error);
      toast.error("Failed to fetch discount");
    } finally {
      setLoading(false);
    }
  }, []);



  const fetchProductData = useCallback(async () => {
    try {
      if ((!isEmpty(id)) && (id > 0)) {
        const response = await ProductService.findOne(id);
        console.log(response, "fetchProductData")
        if (response.status) {
          setData(response.data.data);
        } else {
          toast.error("Failed to load product data.");
        }
      }

    } catch (error) {
      console.error("Error fetching product data:", error);
      toast.error("Failed to load product data.");
    }
  }, [id]);

  useEffect(() => {
    fetchBrand();
    fetchUnit();
    fetchTax();
    fetchDiscount();
    fetchCategories();
  }, [fetchBrand, fetchUnit, fetchTax, fetchDiscount, fetchCategories]);

  useEffect(() => {
    fetchProductData();
  }, [fetchProductData]);

  const previewStyle = {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    gap: "10px",
    transition: "border-color 0.2s ease",
    borderRadius: "9px",
   
  };



  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>{(id > 0) ? "Edit Product" : "Add Product"}</h4>
          <h6>{(id > 0) ? "Update the product details" : "Create a new product"}</h6>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={data}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (

              <Form>
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Product Name <span className="manitory">*</span> </label>
                      <Field type="text" name="productName" className="form-control" />
                      <ErrorMessage name="productName" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Category <span className="manitory">*</span> </label>
                      <Field as="select" name="categoryId" className="form-control" onChange={(e) => {
                        handleCategoriesChange(e);
                        setFieldValue("categoryId", e.target.value);
                      }}>
                        <option value="">Choose Category</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>{category.categoriesName}</option>
                        ))}
                      </Field>
                      <ErrorMessage name="categoryId" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Sub Category <span className="manitory">*</span></label>
                      <Field disabled={!selectedCategories} as="select" name="subCategoriesId" className="form-control" onChange={(e) => {
                        handleSubCategoriesChange(e);
                        setFieldValue("subCategoriesId", e.target.value);
                      }}
                      // disabled={!selectedCategories}
                      >
                        <option value="">Choose Sub Category</option>
                      
                        {subCategories.map((category) => (
                          <option key={category.id} value={category.id}>{category.subCategoriesName}</option>
                        ))}
                      </Field>
                      <ErrorMessage name="subCategoriesId" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Brand <span className="manitory">*</span></label>
                      <Field as="select" name="brandId" className="form-control">
                        <option value="">Choose Brand</option>
                        {brand.map((brands) => (
                          <option key={brands.id} value={brands.id}>{brands.brandName}</option>
                        ))}
                        
                      </Field>
                      <ErrorMessage name="brandId" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Minimum Stock Lock <span className="manitory">*</span> </label>
                      <Field type="number" name="minStockLimit" className="form-control" />
                      <ErrorMessage name="minStockLimit" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Stock <span className="manitory">*</span> </label>
                      <Field type="number" name="stock" className="form-control" />
                      <ErrorMessage name="stock" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Price <span className="manitory">*</span> </label>
                      <Field type="number" name="price" className="form-control" />
                      <ErrorMessage name="price" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Unit <span className="manitory">*</span> </label>
                      <Field as="select" name="unitId" className="form-control">
                        <option value="">Choose Unit</option>
                        {unit.map((units) => (
                          <option key={units.id} value={units.id}>{units.unitType}</option>
                        ))}
                        
                      </Field>
                      <ErrorMessage name="unitId" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Description</label>
                      <Field as="textarea" name="description" className="form-control" placeholder="(Optional)" />
                      <ErrorMessage name="description" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Tax</label>
                      <Field as="select" name="taxId" className="form-control">
                        <option value="">Choose Tax</option>
                        {tax.map((t) => (
                          <option key={t.id} value={t.id}>{t.taxName} {t.taxPars}%</option>
                        ))}
                       
                      </Field>
                      <ErrorMessage name="taxId" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Discount Type</label>
                      <Field as="select" name="discountId" className="form-control">
                        <option value="">Choose Discount Type</option>
                        {discount.map((dis) => (
                          <option key={dis.id} value={dis.id}>{dis.percentage}%</option>
                        ))}
                       
                      </Field>
                      <ErrorMessage name="discountId" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>SKU <span className="manitory">*</span> </label>
                      <Field type="text" name="sku" className="form-control" />
                      <ErrorMessage name="sku" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Status <span className="manitory">*</span> </label>
                      <Field as="select" name="status" className="form-control">
                        <option value="">Choose Status</option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </Field>
                      <ErrorMessage name="status" component="div" className="text-danger" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Shop Image: <span className="manitory">*</span> </label>
                      <FileUpload setUploadRequest={[{
                        attachmentId: data.attachmentId,
                        attachmentName: data.attachmentName
                      }]}
                        setUploadResponse={(response) => {
                          
                          setFieldValue('attachmentId', response[0].attachmentId);
                          setFieldValue('attachmentName', response[0].attachmentName);

                        }}
                      />

                      <ErrorMessage name="attachmentName" component="div" style={{ color: 'red' }} />
                    </div>
                  </div>
                  {(!isEmpty(id) && data.attachmentName) ? (
                    <div className="col-lg-6 col-sm-12 col-12">
                      <div className="form-group">
                        <label>Old Image:</label>
                        <div style={previewStyle}>
                          <img
                            crossOrigin="anonymous"
                            src={`${BASE_URL}${data.attachmentName}`}
                            alt="Uploaded Preview"
                            style={{ maxWidth: "100%", maxHeight: "100px" }}
                          />
                        </div>
                      </div>
                    </div>) : null
                  }

                  <div className="col-lg-12">
                    <button className="btn btn-submit me-2" type="submit" disabled={loading}>
                      {loading ? "Saving..." : (id > 0) ? "Update Product" : "Add Product"}
                    </button>
                    <Link to={"/admin/products"} className="btn btn-cancel" type="reset">
                      Cancel
                    </Link>
                  </div>

                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default ProductsForm;
