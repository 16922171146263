import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BannerService from '../services/user/banner.services';


export const fetchAllBanner = createAsyncThunk(
    'banner/fetchAllBanner',
    async (_, { rejectWithValue }) => {
        try {
            const response = await BannerService.findAllBanner();
            // console.log(response.data,"responseslice")
            return response.data.data; // Return the list of addresses
        } catch (error) {
            return rejectWithValue(error.message); // Handle errors
        }
    }
);



const bannersSlice = createSlice({
    name: 'banner',
    initialState: {
        banner: [], // List of all addresses
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null, // Error message if any
    },
    extraReducers: (builder) => {
        builder
          

            // Fetch All Addresses
            .addCase(fetchAllBanner.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAllBanner.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.banner = action.payload; // Store the list of addresses
            })
            .addCase(fetchAllBanner.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

           
    },
});

export default bannersSlice.reducer;