/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 

const BASE_URL = process.env.REACT_APP_API_URL;

const ProductCard = ({ data, onAddToCart }) => {
 const originalPrice = data.price * 2;
  const discountPercentage = ((originalPrice - data.price) / originalPrice) * 100;


  const [userRating, setUserRating] = useState(null); 

 
  const handleRating = (rating) => {
    setUserRating(rating); 
   
  };

  return (
    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4">
      <div className="product-card flex-fill border rounded-lg shadow-md overflow-hidden">
        
        
        <div className="product-card-img relative">
          <img
            crossOrigin="anonymous"
            src={`${BASE_URL}${data.attachmentName}`}
            alt={`Image of ${data.productName}`}
            className="h-52 w-full object-cover transition-all duration-300 transform hover:scale-105 hover:shadow-lg"
          />
          <h6 className="absolute top-2 left-2 bg-black text-white text-xs px-2 py-1 rounded-full animate-bounce">
            {data.stock} left
          </h6>

          <div className="check-product absolute top-0 right-0 p-2 bg-green-500 rounded-full">
            <i className="fa fa-check text-white" />
          </div>
        </div>

     
        <div className="product-card-content p-4">
          <h5 className="category text-sm text-gray-500">{data.categories_master.categoriesName}</h5>
          
          
          <Link to={`/user/product-details/${data.id}`} className="text-lg font-semibold hover:underline mt-1">
            {data.productName}
          </Link>

        
          <div className="rating mt-2 flex items-center space-x-1">
            <span className="text-sm">Rating:</span>
            {[1, 2, 3, 4, 5].map((value) => (
              <svg
                key={value}
                xmlns="http://www.w3.org/2000/svg"
                className={`h-5 w-5 cursor-pointer ${userRating >= value || data.rating >= value ? 'text-yellow-500' : 'text-gray-300'}`}
                fill="currentColor"
                viewBox="0 0 24 24"
                onClick={() => handleRating(value)} 
              >
                <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
              </svg>
            ))}
            <span className="text-sm text-gray-500">({data.ratingCount})</span>
          </div>
       
      
          <h6 className="price text-xl text-green-500 mt-2">
            ₹{data.price} <span className="line-through text-gray-400">₹{originalPrice}</span>
          </h6>
          <span className="discount text-sm text-red-500">{Math.round(discountPercentage)}% off</span>
          
        
            <div className="bank-offer text-sm text-blue-500 mt-2">
              <i className="fa fa-credit-card mr-1" /> Bank Offer: SBI
            </div>
       
          <button
            className="w-full uppercase py-2 mt-4 text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300 font-semibold rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
            onClick={() => onAddToCart(data)}
          >
            Add to Cart
          </button>

        </div>
      </div>
    </div>
  );
};

export default ProductCard;
