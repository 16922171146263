const url = process.env.REACT_APP_API_URL;

function combineURLs(baseURL, relativeURL) {
  return relativeURL
    ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '')
    : baseURL;
}

export const BASE_URL = combineURLs(url, 'api/v1/');
// export const RAZORPAY_KEY = 'rzp_live_XLVqNvfof3ysOM';
// export const RAZORPAY_SECRET = '33WusnierpJv9c1uvYa9KU8Z';
export const RAZORPAY_KEY = 'rzp_test_ER1KdgafQbtvk3';
export const RAZORPAY_SECRET = 'hMlGwsWDL9XjXOZOZ1t8zKaP';