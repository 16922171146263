import ApiInstance from "../../axios/api.auth";

const findAll = async(page, size, search) => {
  return await ApiInstance.get(`admin/coupons?page=${page}&size=${size}&search=${search}`);
};
const findOne = async (id) => {
  return await ApiInstance.get(`admin/coupons/${id}`);
};
const create = async(data) => {
  return await ApiInstance.post("admin/coupons/", data);
};
const destroy = async(id) => {
  return await ApiInstance.delete(`admin/coupons/${id}`);
};

const update = async(id, data) => {
  return await ApiInstance.put(`admin/coupons/${id}`, data);
};

const deactivate = async(id) => {
  return await ApiInstance.patch(`admin/coupons/deactivate/${id}`);
};

const CouponService = {
  findAll,
  findOne,
  create,
  update,
  destroy,
  deactivate
};

export default CouponService;
