

import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './include/Header';

function AdminLayout() {
  return (
    <>

      <div className="flex flex-col h-screen main-wrapper">    
          <Header />     
        <div className="flex flex-1 overflow-hidden">
          <main className="flex-1 overflow-y-auto p-4">
            <Outlet />
          </main>
        </div>
      </div>

    </>
  );
}

export default AdminLayout;
