import ApiInstance from "../../axios/api.auth";

const findAll = async(page, size, search) => {
  return await ApiInstance.get(`/admin/brands?page=${page}&size=${size}&search=${search}`);
};
const findAllBrand = async() => {
  return await ApiInstance.get(`/admin/brands`);
};
const findOne = async (id) => {
  return await ApiInstance.get("/admin/brands/" + id);
};
const create = async(data) => {
  return await ApiInstance.post("/admin/brands", data);
};
const destroy = async(id) => {
  return await ApiInstance.delete("/admin/brands/" + id);
};

const update = async(id, data) => {
  return await ApiInstance.put("/admin/brands/" + id, data);
};

const BrandService = {
  findAll,
  findOne,
  create,
  update,
  destroy,
  findAllBrand,
};

export default BrandService;
