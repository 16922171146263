import ApiInstance from "../../axios/api.auth";

const findAll = async (page, size, search) => {
  return await ApiInstance.get(`/admin/categories?page=${page}&size=${size}&search=${search}`);
};
const findAllCategories = async () => {
  return await ApiInstance.get(`/admin/categories`);
};
const findOne = async (id) => {
  return await ApiInstance.get("/admin/categories/" + id);
};
const create = async (data) => {
  return await ApiInstance.post("/admin/categories/", data);
};
const destroy = async (id) => {
  return await ApiInstance.delete("/admin/categories/"+id);
};

const update = async (id, data) => {
  return await ApiInstance.put("/admin/categories/" + id, data);
};

const CategoriesService = {
  findAll,
  findOne,
  create,
  update,
  destroy,
  findAllCategories
};

export default CategoriesService;
