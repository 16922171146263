import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { DataGrid } from "@mui/x-data-grid";

const DataTable = ({
  columns,
  data,
  loading,
  totalCount,
  paginationModel,
  onPaginationModelChange,
}) => {
  return (
    <DataGrid
      rows={data}
      columns={columns}
      loading={loading}
      rowCount={totalCount}
      initialState={{
        pagination: { paginationModel },
      }}
      pageSizeOptions={[10, 20, 30, 40, 50]}
      sx={{
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "&.MuiDataGrid-root": {
          border: "none",
        },
      }}
      paginationMode="server"
      autoHeight
      disableColumnMenu
      onPaginationModelChange={onPaginationModelChange}
    />
  );
};

// Adding prop types for type checking
DataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  paginationModel: PropTypes.object.isRequired,
  onPaginationModelChange: PropTypes.func.isRequired,
};

export default DataTable;
