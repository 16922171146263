import ApiInstance from "../../axios/api.auth";

const getProductByCatId = async (categoryId) => {
  return await ApiInstance.get(`public/products?categoryId=${categoryId}`);
};
const getAllProduct = async () => {
  return await ApiInstance.get('public/products');
};
const findOneByProductId = async (productId) => {
  return await ApiInstance.get(`public/products/${productId}`);
};
const findAllCategories = async () => {
  return await ApiInstance.get("public/categories");
};

const ProductsService = {
  getProductByCatId,
  getAllProduct,
  findOneByProductId,
  findAllCategories,
};

export default ProductsService;
