import ApiInstance from "../../axios/api.auth";
const create = async(data) => {
  return await ApiInstance.post("/user/address/", data);
};
const getAllAddress = async () => {
  return await ApiInstance.get('/user/address/');
};

const findByIdAddress = async (id) => {
  return await ApiInstance.get(`/user/address/${id}`);
}; 

const destroy = async(id) => {
  return await ApiInstance.delete(`/user/address/${id}`);
};

const update = async(id, data) => {
  return await ApiInstance.put(`/user/address/${id}`, data);
};

const AddressService = {
  create,
  getAllAddress,
  findByIdAddress,
  update,
  destroy
};

export default AddressService;

