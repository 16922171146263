import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import ProductService from "../../../services/admin/product.services";
import DataTable from "../../../components/Datatable/DataTable";
import DeletePopup from "../../../components/popups/DeletePopup"
import { toast } from "react-toastify";
import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";

const Products = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const BASE_URL = process.env.REACT_APP_API_URL;
  const columns = [
    {
      field: 'id',
      headerName: 'S.NO',
      width: 90,
      renderCell: (params) => {
        const { page, pageSize } = paginationModel;
        const rowIndex = (page * pageSize) + params.api.getAllRowIds().indexOf(params.id);
        return <strong>{rowIndex + 1}</strong>; 
      }
    },
    { field: 'productName', headerName: 'Product Name', width: 130 },
    {
      field: 'attachmentName',
      headerName: 'Product Image',
      width: 120,
      renderCell: (params) => {
        const imageUrl = `${BASE_URL}${params.value}?t=${new Date().getTime()}`; 
        return (
          <img
            className="product-img"
            crossOrigin="anonymous"
            src={imageUrl}
            alt=""
            style={{ width: '50px', height: '50px' }}
          />
        );
      },
    },
    {
      field: 'categories_master',
      headerName: 'Category',
      width: 100,
      valueGetter: (params) => params.categoriesName,
    },
    {
      field: 'sub_category',
      headerName: 'Sub Category',
      width: 130,
      valueGetter: (params) => params.subCategoriesName,
    },
    { field: 'brand_master', headerName: 'Brand', width: 100, valueGetter: (params) => params.brandName, },

    { field: 'sku', headerName: 'Sku', width: 100 },
    { field: 'minStockLimit', headerName: 'Stock Lock', width: 100, cellClassName: 'text-red-400 font-bold' },
    { field: 'stock', headerName: 'Stock', width: 100, cellClassName: 'text-gray-500 font-bold' },
    // { field: 'unit_master', headerName: 'Unit', width: 100, valueGetter: (params) => params.unitType, },
    {
      field: 'price', headerName: 'Price', width: 150,
      //   renderCell: (params) => {
      //     const priceFormatted = `₹${params.value.toFixed(2)}`;
      //     const unit = params.row.unit_master || '';  // Get the unit (e.g., kg) from unit_master field
      //     return `${priceFormatted} /${unit.unitType}`;
      //   }
      // },

      renderCell: (params) => {
        const priceFormatted = `₹${params.value.toFixed(2)}`;
        const unit = params.row.unit_master || ''; 

       
        const priceStyle = { color: '#28c76f', fontWeight: 'bold' };
        const unitStyle = { color: 'orange' }; 

        return (
          <span style={priceStyle}>
            {priceFormatted}
            <span style={unitStyle}> /{unit.unitType}</span>
          </span>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 100,
      renderCell: (params) => new Date(params.value).toLocaleDateString(),
    },
    { field: 'tax_master', headerName: 'Tax (%)', width: 100, valueGetter: (params) => params.taxName + " " + params.taxPars },
    { field: 'discount_master', headerName: 'Discount (%)', width: 130, valueGetter: (params) => params.percentage },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => {
        const status = params.value ? 'Active' : 'Inactive';
        const badgeClass = params.value ? 'badge' : 'badge'; 

        const badgeStyle = {
          padding: '5px 10px',
          fontSize: '12px',
          backgroundColor: params.value ? '#90EE90' : '#FFCCCB', 
          color: params.value ? '#006400' : '#8B0000', 
        };

        return (
          <span className={badgeClass} style={badgeStyle}>
            {status}
          </span>
        );
      }

    },
    { field: 'description', headerName: 'description', width: 100 },
    {
      field: 'action', headerName: 'Action', width: 150, renderCell: (params) => (
        <div className="flex mt-3">
          <Link className="me-3" to={`detail/${params.row.id}`}>
            <img src="../../../assets/img/icons/eye.svg" alt="Eye" />
          </Link>
          <Link className="me-3" to={`edit/${params.row.id}`}>
          <PencilIcon className="h-5 w-5 text-green-600"/>
          </Link>
          <Link className="me-3 confirm-text" onClick={() => handleDelete(params.row.id)}>
          <TrashIcon className="h-5 w-5 text-red-600"/>
          </Link>
        </div>
      ),
    },
  ];

  const fetchData = useCallback(async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const response = await ProductService.findAll(page, pageSize, searchTerm);
      console.log(response, "Response:")
      setData(response.data.data.rows);
      setTotalCount(response.data.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
  }, [paginationModel, fetchData, searchTerm]);

  const handleDelete = async (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await ProductService.destroy(deleteId);
      handleCloseDialog();
      toast.success("The product has been deleted!");
      fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
    } catch (error) {
      toast.error('Error deleting product: ' + error);
      console.error('Error deleting product:', error);
    }
  };


  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>Product List</h4>
          <h6>Manage your products</h6>
        </div>
        <div className="page-btn">
          <Link to={"add"} className="btn btn-added"><img src="../../../assets/img/icons/plus.svg" alt="img" className="me-1" />Add New Product</Link>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="table-top">
            <div className="search-set">
              <div className="search-input">
                <Link className="btn btn-searchset"><img src="../../../assets/img/icons/search-white.svg" alt="img" /></Link>
                <div id="DataTables_Table_0_filter" className="dataTables_filter"><label> <input type="search" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} className="form-control form-control-sm" placeholder="Search..." aria-controls="DataTables_Table_0" /></label></div></div>
            </div>
          </div>
          <div className="table-responsive">
            <DataTable
              columns={columns}
              data={data}
              loading={loading}
              totalCount={totalCount}
              paginationModel={paginationModel}
              onPaginationModelChange={(model) => setPaginationModel(model)}
            />
          </div>
          <DeletePopup
            open={openDialog}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmDelete}
          />
        </div>
      </div>
    </div>

  )
}

export default Products
