import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.services";
import { toast } from "react-toastify";
import OtpInput from "../../components/OtpInput";
import CountdownTimer from "../../components/CountdownTimer";
import { useDispatch, useSelector } from "react-redux";
import { login, selectCurrentUser } from "../../features/auth.slice";


const Login = () => {
  const form = useRef();
  const [mobile, setMobile] = useState("");
  const [otp, setOTP] = useState("");
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(selectCurrentUser);

  const otpDuration = 120; // 2 minutes

  const validateMobile = (mobile) => {
    const regex = /^[0-9]{10}$/; // Assuming 10-digit mobile numbers
    return regex.test(mobile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage("");
    setLoading(true);
    // Validate the mobile number before proceeding
    if (!validateMobile(mobile)) {
      setMessage("Please enter a valid mobile number.");
      setLoading(false); // Stop the loading spinner
      return; // Prevent further submission if validation fails
    }
    AuthService.login(mobile).then(
      (data) => {
        if (data.success) setLoading(false);
        setShowOtpForm(true);
        toast.success(data.message);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
        toast.error(resMessage);
      }
    );
  };
  useEffect(() => {
    if (user) navigate('/dashboard');
  }, [navigate, user]);

  const maskingMobile = (phoneNumber) => {
    let subNum = phoneNumber.toString().substring(0, 6);
    subNum = subNum + "XXXX";
    return subNum;
  };

  const handleOTPChange = (otp) => {
    setOTP(otp);
  };

  const handleVerifyOTP = async (e) => {

    e.preventDefault();
    setMessage("");
    setLoading(true);
    await AuthService.otpVerify(mobile, otp).then(
      async (response) => {
        await dispatch(login(response)).unwrap()
          .then((data) => {
            toast.success(data.message);
            const redirectPath = location.state?.from || '/dashboard';
            navigate(redirectPath);
            //console.log('Logged in successfully');
          });

      },
      (error) => {
        console.log(error.response);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
        toast.error(resMessage);
      }
    );
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="account-content">
          <div className="login-wrapper">
            <div className="login-content">
              <div className="login-userset">
                <section className="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
                  <img
                    src="assets/img/logo.png"
                    className="items-center h-50 w-50"
                    alt="img"
                  />
                </section>
                {!showOtpForm ? (
                  <>
                    <div className="login-userheading text-center	">
                      <h3>Enter Your Phone Number</h3>
                      <p>We will send you the 6 digit verification code</p>
                    </div>

                    <form onSubmit={handleSubmit} ref={form}>
                      <div className="form-login">
                        {message && (
                          <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                              {message}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="form-login">
                        {/* <label>Enter Your Phone Number: </label> */}
                        <div className="form-addons">
                          <input
                            type="text"
                            className="border rounded"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            placeholder="Enter Your Phone Number"
                            maxLength={10} // Limit input to 10 characters
                            required
                          />
                        </div>
                      </div>
                      <div className="form-login">
                        <button className="w-full uppercase py-3 text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300 font-semibold rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105" disabled={loading}>
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                          <span> GENERATE OTP</span>
                        </button>
                      </div>
                    </form>
                    <div className="form-setlogin">
                      <h4>Or sign up</h4>
                    </div>
                    <div className="signinform text-center">
                      <h4>Don’t have an account? <Link href="" className="hover-a">Sign Up</Link></h4>
                    </div>

                  </>
                ) : (
                  <>
                    <form onSubmit={handleVerifyOTP} ref={form}>
                      <div className="login-userheading text-center">
                        <h3>OTP Verification</h3>
                        <h4>
                          Enter the 6-digit OTP sent to your register mobile
                          number.
                        </h4>
                        <span className="font-bold">
                          +91 {maskingMobile(mobile)}
                        </span>
                      </div>
                      <div className="form-login">
                        {message && (
                          <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                              {message}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="form-login">
                        <OtpInput length={6} onChange={handleOTPChange} />
                      </div>
                      <div className="flex justify-center text-center mt-3">
                        <CountdownTimer duration={otpDuration} />
                      </div>
                      <div className="form-login">
                        <button className="w-full uppercase py-3 text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300 font-semibold rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105" disabled={loading}>
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                          <span> VERIFY & CONTINUE</span>
                        </button>
                      </div>

                    </form>
                  </>
                )}
              </div>
              <footer className="bg-white text-gray-700 text-xs py-2">
                <div className="container mx-auto text-center">
                  <p>&copy; 2024 <Link to={"https://www.nestingprobe.com/"}>Nesting Probe</Link> All Rights Reserved.</p>
                </div>
              </footer>
            </div>
            <div className="login-img">
              <img src="assets/img/login.jpg" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
