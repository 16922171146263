/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback} from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TaxService from "../../../services/admin/tax.services"
import { toast } from "react-toastify";
import { useParams, useNavigate, Link} from "react-router-dom";
import { isEmpty } from "validator";


const initialValues = {
  id: 0,
  taxName: "",
  taxPars: "",
  status: "",
};
const TaxForm = () => {
  const params = useParams();
  const id = params.id;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialValues);
  const navigate = useNavigate();
  
  const validationSchema = Yup.object({
    taxName: Yup.string().required("taxName is required"),
    taxPars: Yup.number()
      .typeError("Tax Pars must be a number")
      .required("Tax Pars is required"),
    status: Yup.string().required("status is required"),
  });
  
  
  const handleSubmit = async (values, { resetForm }) => {
    const data = {
      taxName: values.taxName,
      taxPars: values.taxPars,
      status: values.status,
    };
    setLoading(true);
    try {
      if ((!isEmpty(id)) && (id > 0)) {
        const response = await TaxService.update(id, data);
        if (response.data) {
          navigate("/admin/tax");
          toast.success("tax updated successfully!");
        } else {
          toast.error(response.message);
        }
      } else {
        const response = await TaxService.create(data);
        if (response.data) {
          navigate("/admin/tax");
          toast.success("tax added successfully!");
          resetForm();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.error("Error submitting tax:", error);
      toast.error(id ? "Failed to update tax." : "Failed to create tax.");
    } finally {
      setLoading(false);
    }
    };

  
      const fetchTaxData = useCallback(async () => {
      try {
        if ((!isEmpty(id)) && (id > 0)) {
          const response = await TaxService.findOne(id);
          console.log(response)
          if (response.status) {
            const data = response.data.data;
            setData({
              id: data.id,
              taxName: data.taxName,
              taxPars: data.taxPars,
              status: data.status,
              
            });
          } else {
            toast.error("Failed to load tax data.");
          }
        }
  
      } catch (error) {
        console.error("Error fetching tax data:", error);
        toast.error("Failed to load tax data.");
      }
    });
  
    useEffect(() => {
      fetchTaxData();
    }, []);
  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
        <h4>{(id > 0) ? "Edit Tax" : "Add Tax"}</h4>
        <h6>{(id > 0) ? "Update the tax details" : "Create a new tax"}</h6>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={data}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className="row align-top">
                  <div className="col-12">
                    <div className="form-group">
                    <label>Tax Name<span className="manitory">*</span></label>
                      <Field
                        type="text"
                        name="taxName"
                        className="form-control"
                        id="taxName"
                      />
                      <ErrorMessage
                        name="taxName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                    <label>Tax Rate(%)<span className="manitory">*</span></label>
                      <Field
                        type="text"
                        name="taxPars"
                        className="form-control"
                        id="taxPars"
                      />
                      <ErrorMessage
                        name="taxPars"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <Field
                        as="select"
                        name="status"
                        className="form-control"
                        id="status"
                      >
                        <option value="">Choose Status</option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </Field>
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <button className="btn btn-submit me-2" type="submit" disabled={loading}>
                    {loading ? "Saving..." : (id>0) ? "Update Tax" : "Add Tax"}
                    </button>
                    <Link to={"/admin/tax"} className="btn btn-cancel" type="reset">
                      Cancel
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default TaxForm;
