import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from '../features/auth.slice';
import cartReducer from '../features/cart.slice';
import categoryReducer from '../features/category.slice';
import productReducer from '../features/product.slice';
import errorReducer from '../features/error.slice';
import addressReducer from '../features/addresses.slice';
import locationReducer from '../features/location.slice';
import paymentReducer from '../features/payment.slice';
import orderReducer from '../features/order.slice';
import bannerReducer from '../features/banners.slice';


import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // default storage is localStorage or sessionStorage

// Configuration for redux-persist
const persistConfig = {
    key: "root", // The key under which the persisted state is stored in localStorage
    version: 1,  // Version of the persistence layer (can be used for migrations)
    storage,     // We're using the default localStorage
};


const rootReducer = combineReducers({
    auth: persistReducer(persistConfig, authReducer),// Only persist the Auth reducer
    cart: cartReducer,
    categories: categoryReducer, 
    products: productReducer, 
    error: errorReducer,
    address: addressReducer,
    location: locationReducer,
    payment: paymentReducer,
    order: orderReducer,
    banner: bannerReducer
   
});

// Configure Redux store with persisted reducer and middleware
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], // Ignoring actions related to persistence
            },
        }),
});

// Persistor instance
export const persistor = persistStore(store);
