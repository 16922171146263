
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAddress } from '../../features/addresses.slice';

function AddressDropdown({ setSelectedAddress }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAddressText, setSelectedAddressText] = useState('Select an address...');
  const navigate = useNavigate();

  const { addresses, status, error } = useSelector((state) => state.address);

  // console.log(addresses, "addresses")

 

  const toggleDropdown = () => setIsOpen(!isOpen);


  const selectAddress = (address) => {
    setSelectedAddress(address); // Update selected address in the parent
    setSelectedAddressText(
      `${address.fullName}, ${address.houseNo}, ${address.landmark}, ${address.city}, ${address.district}, ${address.state}, ${address.pinCode}, ${address.phone}`
    ); // Display selected address in the dropdown
    setIsOpen(false); // Close the dropdown after selection
  };


  const addNewAddress = () => {
    navigate("/user/address/add");
  };

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchAllAddress()); // Fetch all addresses on component load
    }
  }, [status, dispatch]);


  return (
    <div className="relative inline-block w-full">
      <label htmlFor="address" className="text-md font-medium text-gray-700">Deliver To :<span className="manitory"> *</span></label>

      <div className="relative">
        <button
          className="w-full px-4 py-2 text-left bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 flex items-center justify-between"
          onClick={toggleDropdown}
        >
          <span className="text-gray-700">{selectedAddressText}</span>
          <svg className="w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
        {isOpen && (
          <ul className="absolute left-0 z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
            {addresses.map((address, index) => (
              <li
                key={index}
                className="cursor-pointer hover:bg-gray-100 px-4 py-2 text-gray-700"
                onClick={() => selectAddress(address)}
    
              >
                {address.fullName}
                {address.houseNo}, {address.landmark}, {address.city}, {address.district}, {address.state}, {address.pinCode}, 
                {address.phone}

              </li>
            ))}

            <li
              className="cursor-pointer flex hover:bg-gray-100 px-4 py-2 text-blue-600 text-sm"
              onClick={addNewAddress} // Handle adding a new address
            >
              <img src="../../../assets/img/icons/plus.svg" alt="img" />
              <span className="inline-block py-1 px-2 bg-blue-100 rounded-full hover:bg-blue-200">Add New Address</span>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}

export default AddressDropdown;

