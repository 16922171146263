import axios from "axios";
import { store } from "../store"; // Import your Redux store
import { BASE_URL } from "../utils/utils"; // Your API base URL
import { reset, setLoading, setLoadingProgress } from "../features/auth.slice"; // Redux actions
import { isTokenExpired } from "../middleware/auth.verify";
import { setError } from "../features/error.slice"; // Error handling
import { useNavigate } from "react-router-dom";

const instance = axios.create({
    baseURL: BASE_URL,
    // timeout: 5000, // Timeout after 5 seconds
    headers: {
        "Content-Type": "application/json",
    }
});

// Request interceptor to handle loading state and token attachment
instance.interceptors.request.use(
    (config) => {
        store.dispatch(setLoading(true)); // Set loading state to true
        store.dispatch(setLoadingProgress(0)); // Reset loading progress to 0

        const token = store.getState().auth.accessToken; // Get token from Redux store
        if (token && !isTokenExpired(token)) {
            config.headers.Authorization = `Bearer ${token}`; // Attach token if not expired
        } else if (token && isTokenExpired(token)) {
            store.dispatch(reset()); // Reset auth state if the token is expired
            console.log("Token expired, user logged out.");
            const navigate = useNavigate(); // Access the navigate function
            navigate('/'); // Redirect to login page
        }

        return config; // Return modified config
    },
    (error) => {
        store.dispatch(setLoading(false)); // Set loading to false on error
        return Promise.reject(error); // Reject promise with the error
    }
);

// Response interceptor to handle loading state and error handling
instance.interceptors.response.use(
    (response) => {
        // Success handler: Set loading state to false and reset progress
        store.dispatch(setLoading(false));
        store.dispatch(setLoadingProgress(100));

        // Handle download progress if provided
        if (response.config.onDownloadProgress) {
            response.config.onDownloadProgress(response);
        }

        return response; // Return the successful response
    },
    (error) => {
        const originalConfig = error.config; // Access the original request configuration
        const status = error.response?.status;

        // Handle the case where token is expired (401 Unauthorized)
        if (status === 401 && !originalConfig._retry) {
            originalConfig._retry = true; // Prevent retrying more than once

            // If token is expired, reset authentication state and redirect user to login
            store.dispatch(reset()); // Reset auth state (log the user out)
            const navigate = useNavigate(); // Access the navigate function
            navigate('/'); // Redirect to login page
            console.log("Token expired, please log in again.");
        }

        // Handle timeout errors (ECONNABORTED)
        if (error.code === 'ECONNABORTED') {
            store.dispatch(setLoading(false));
            store.dispatch(setLoadingProgress(100));
        }
        if (error.code === 'ERR_NETWORK_CHANGED') {
            // Network error, capture it and dispatch the error to Redux
            store.dispatch(setError('Network error: Your connection was interrupted. Please check your network.'));
        } else {
            store.dispatch(setError(error.message)); // Handle other types of errors
        }
        store.dispatch(setLoading(false)); // Set loading to false on error
        store.dispatch(setLoadingProgress(100)); // Set progress to 100 on error

        return Promise.reject(error); // Reject the promise with the error
    }
);

export default instance; // Export Axios instance


// import axios from "axios";
// import { store } from "../store"; // Import your Redux store
// import { BASE_URL } from "../utils/utils"; // Your API base URL
// import { reset, setLoading, setLoadingProgress } from "../features/auth.slice"; // Redux actions
// import { isTokenExpired } from "../middleware/auth.verify";
// import { useNavigate } from "react-router-dom";
// import { setError } from "../features/error.slice";

// const instance = axios.create({
//     baseURL: BASE_URL,
//     timeout: 5000, // Timeout after 5 seconds
//     headers: {
//         "Content-Type": "application/json",
//     }
// });

// // Request interceptor to handle loading state and token attachment
// instance.interceptors.request.use(
//     (config) => {
//         store.dispatch(setLoading(true)); // Set loading state to true
//         store.dispatch(setLoadingProgress(0)); // Reset loading progress to 0

//         const token = store.getState().auth.accessToken; // Get token from Redux store
//         if (token && !isTokenExpired(token)) {
//             config.headers.Authorization = `Bearer ${token}`; // Attach token if not expired
//         } else if (token && isTokenExpired(token)) {
//             store.dispatch(reset()); // Reset auth state if the token is expired
//             // Optionally, you could redirect the user to the login page here
//             // Redirect to login page if the token has expired
//             const navigate = useNavigate(); // Access the navigate function
//             navigate('/login'); // Redirect to login page
//             console.log("Token expired, please log in again.");
//         }

//         return config; // Return modified config
//     },
//     (error) => {
//         store.dispatch(setLoading(false)); // Set loading to false on error
//         return Promise.reject(error); // Reject promise with the error
//     }
// );

// // Response interceptor to handle loading state and error handling
// instance.interceptors.response.use(
//     (response) => {
//         // Success handler: Set loading state to false and reset progress
//         store.dispatch(setLoading(false));
//         store.dispatch(setLoadingProgress(100));

//         // Handle download progress if provided
//         if (response.config.onDownloadProgress) {
//             response.config.onDownloadProgress(response);
//         }

//         return response; // Return the successful response
//     },
//     (error) => {
//         const originalConfig = error.config; // Access the original request configuration
//         const status = error.response?.status;

//         // Handle the case where token is expired (401 Unauthorized)
//         if (status === 401 && !originalConfig._retry) {
//             originalConfig._retry = true; // Prevent retrying more than once

//             // If token is expired, reset authentication state and redirect user to login
//             store.dispatch(reset()); // Reset auth state (log the user out)
//             // Redirect to login page (using react-router for example)
//             // Redirect to login page if the token has expired
//             const navigate = useNavigate(); // Access the navigate function
//             navigate('/login'); // Redirect to login page

//             console.log("Token expired, please log in again.");
//         }

//         // Handle timeout errors (ECONNABORTED)
//         if (error.code === 'ECONNABORTED') {
//             store.dispatch(setLoading(false));
//             store.dispatch(setLoadingProgress(100));
//         }
//         if (error.code === 'ERR_NETWORK_CHANGED') {
//             // Network error, capture it and dispatch the error to Redux
//             store.dispatch(setError('Network error: Your connection was interrupted. Please check your network.'));
//           } else {
//             store.dispatch(setError(error.message)); // Handle other types of errors
//           }
//         store.dispatch(setLoading(false)); // Set loading to false on error
//         store.dispatch(setLoadingProgress(100)); // Set progress to 100 on error

//         return Promise.reject(error); // Reject the promise with the error
//     }
// );

// export default instance; // Export Axios instance
