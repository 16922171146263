import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    user: null,  // Store the user data
    accessToken: null,  // Store the access token
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorMessage: "",  // To store the error message
};

// Simulated login API call (Replace this with actual API call logic)
export const login = createAsyncThunk(
    'auth/login',
    async (user, { rejectWithValue }) => {
        try {
            return { user: user, accessToken: user.token };
        } catch (e) {
            return rejectWithValue(e.message || "Login failed");
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.user = null;
            state.accessToken = null;
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.errorMessage = "";
            
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
        setLoading(state, action) {
            state.isLoading = action.payload; // Set loading state
        },
        setLoadingProgress(state, action) {
            state.loadingProgress = action.payload; // Set loading progress
        },
    },
    extraReducers(builder) {
        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
            state.isSuccess = false;
            state.errorMessage = "";
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.user = action.payload.user;
            state.accessToken = action.payload.accessToken;
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = true;
        });
        builder.addCase(login.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.isSuccess = false;
            state.errorMessage = action.payload || "Login failed!";
        });
    }
});

export const { reset, setAccessToken, setLoading, setLoadingProgress } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentAccessToken = (state) => state.auth.accessToken;
export const selectAuthErrorMessage = (state) => state.auth.errorMessage;
