import ApiInstance from "../../axios/api.auth";

const findAll = async(page, size, search) => {
  return await ApiInstance.get(`admin/subcategories?page=${page}&size=${size}&search=${search}`);
};
const findAllCategories = async (categoriesId) => {
  return await ApiInstance.get("admin/subcategories/categories/" + categoriesId);
};
const findOne = async (id) => {
  return await ApiInstance.get("admin/subcategories/" + id);
};
const create = async(data) => {
  return await ApiInstance.post("admin/subcategories/", data);
};
const destroy = async(id) => {
  return await ApiInstance.delete("admin/subcategories/" + id);
};

const update = async(id, data) => {
  return await ApiInstance.put("admin/subcategories/" + id, data);
};

const SubCategoriesService = {
  findAll,
  findAllCategories,
  findOne,
  create,
  update,
  destroy,
};

export default SubCategoriesService;
