import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Dialog,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuItems,
    MenuItem,
    MenuButton,
    Transition,
    PopoverGroup,
} from "@headlessui/react";
import {
    Bars3Icon,
    ShoppingCartIcon,
    WrenchScrewdriverIcon,
    XMarkIcon
} from "@heroicons/react/24/outline";
import { ChevronDownIcon, ShoppingBagIcon, MapPinIcon, UserCircleIcon, PowerIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { reset, selectCurrentUser } from "../../../../features/auth.slice";

const orderToAction = [
    { name: "MY ORDER", href: "/user/my-order" },
    { name: "My Cart", href: "/user/cart" },
];
const userToAction = [
    { name: "MY Profile", href: "/user/profile" },
    { name: "My Address", href: "/user/address" },
];
const settingToAction = [
    { name: "Notifications", href: "/user/notifications" },
    { name: "Helps", href: "/user/helps" },
];

const userNavigation = [
    { name: "MY PROFILE", href: "/user/profile", icon: UserCircleIcon },
    { name: "MY ORDER", href: "/user/my-order", icon: ShoppingBagIcon },
    { name: "MY ADDRESS", href: "/user/address", icon: MapPinIcon },
    { name: "SIGNOUT", href: "/login", icon: PowerIcon },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const items = useSelector((state) => state.cart.items);
    const itemCount = items.length;
    const currentUser = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(reset());
        navigate("/");
    };

    return (
        <header className="bg-white">
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-3 lg:px-8" aria-label="Global">
                {/* Logo Section */}
                <div className="flex lg:flex-1">
                    <Link to="/" className="-m-1.5 p-1.5">
                        <span className="font-semibold">NESTING PROBE</span>
                    </Link>
                </div>

                {/* Mobile View (Cart & Menu Icon) */}
                <div className="flex lg:hidden">
                    {/* Cart Icon */}
                    <div className="mr-2 text-center relative">
                        <Link to="/user/cart" className="relative inline-flex items-center cursor-pointer hover:bg-gray-300 rounded-full p-1">
                            <ShoppingCartIcon className={`h-6 w-6 ${itemCount > 0 ? 'text-gray-800' : 'text-gray-700'}`} />
                            {itemCount > 0 && (
                                <span className="badge h-5 w-5 text-center rounded-full bg-red-500 text-white absolute -top-1 -right-1 text-xs p-1">
                                    {itemCount}
                                </span>
                            )}
                        </Link>
                    </div>

                    {/* Hamburger Menu */}
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                        aria-label="Open main menu"
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>

                {/* Desktop View - Cart and User Profile */}
                <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-3">
                    {currentUser ? (
                        <>
                            {/* Cart Icon */}
                            <div className="ml-2 text-center relative">
                                <Link to="/user/cart" className="relative inline-flex items-center cursor-pointer hover:bg-gray-300 rounded-full p-1">
                                    <ShoppingCartIcon className={`h-6 w-6 ${itemCount > 0 ? 'text-gray-800' : 'text-gray-700'}`} />
                                    {itemCount > 0 && (
                                        <span className="badge h-5 w-5 text-center rounded-full bg-red-500 text-white absolute -top-1 -right-1 text-xs p-1">
                                            {itemCount}
                                        </span>
                                    )}
                                </Link>
                            </div>

                            {/* User Profile and Menu */}
                            <div className="ml-2 uppercase text-center relative">
                                <div className="text-xs font-medium text-gray-900">
                                    <Link to="profile" className="flex items-center">
                                        {currentUser.openId}
                                        <span aria-hidden="true" className="ml-1">→</span>
                                    </Link>
                                </div>
                                <small className="text-xs text-gray-500">{currentUser.role}</small>
                            </div>

                            <div className="ml-2 flex items-center">
                                <Menu as="div" className="relative ml-3">
                                    <MenuButton
                                        className="flex items-center justify-center rounded-full bg-gray-100 text-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-800"
                                        aria-label="Open user menu"
                                    >
                                        <span className="sr-only">Open user menu</span>
                                        <img
                                            className="h-8 w-8 rounded-full"
                                            src={currentUser.profilePicture || require("../../../../assets/logo.png")}
                                            alt="Profile"
                                        />
                                    </MenuButton>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <MenuItems className="absolute flex-auto right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-gray-100 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {userNavigation.map((item) => (
                                                <MenuItem key={item.name}>
                                                    {({ close }) => (
                                                        <Link
                                                            to={item.href}
                                                            className="flex bold uppercase px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 transition items-center justify-start"
                                                            onClick={item.name === "SIGNOUT" ? handleLogout : undefined}
                                                            aria-label={`Go to ${item.name}`}
                                                        >
                                                            <item.icon className="h-5 w-5 mr-4" aria-hidden="true" />
                                                            {item.name}
                                                        </Link>
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </MenuItems>
                                    </Transition>
                                </Menu>
                            </div>
                        </>
                    ) : (
                        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                            <div className="ml-2 uppercase text-center">
                                <div className="text-xs font-medium text-gray-900">
                                    <PopoverGroup className="hidden lg:flex lg:gap-x-12">
                                        <Link to="/user/cart" className="flex items-center">
                                            <ShoppingCartIcon className="h-6 w-6 text-gray-500 gap-2" />
                                        </Link>
                                        <Link to="login" className="text-sm font-semibold leading-6 text-gray-900">
                                            Signin
                                        </Link>
                                    </PopoverGroup>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </nav>

            {/* Mobile Menu Dialog */}
            <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    {/* Close Button */}
                    <div className="flex items-center justify-between">
                        <Link to="#" className="-m-1.5 p-1.5">
                            <img
                                className="h-8 w-8 rounded-full"
                                src={require("../../../../assets/logo.png")}
                                alt="Profile"
                            />
                        </Link>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                            aria-label="Close menu"
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>

                    {/* Mobile Menu Links */}
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {currentUser && (
                                    <div className="ml-4 uppercase relative py-4">
                                        <div className="font-semibold text-gray-900 justify-items-center">
                                            <img
                                                className="h-10 w-10 relative rounded-full"
                                                src={currentUser.profilePicture || require("../../../../assets/logo.png")}
                                                alt="Profile"
                                            />
                                            <Link to="profile" className="items-center">
                                                {currentUser.openId}
                                                <span aria-hidden="true" className="ml-1">→</span>
                                            </Link>
                                            <small className="text-gray-500 font-semibold">{currentUser.role}</small>
                                        </div>                                       
                                    </div>
                                )}
                                {/* Orders Option */}
                                <Disclosure as="div" className="-mx-3">
                                    {({ open }) => (
                                        <>
                                            <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 bg-gray-100 hover:bg-gray-200">
                                                <ShoppingBagIcon className="h-6 w-6" aria-hidden="true" /> Order Menu
                                                <ChevronDownIcon
                                                    className={classNames(open ? "rotate-180" : "", "h-5 w-5 flex-none")}
                                                    aria-hidden="true"
                                                />
                                            </DisclosureButton>
                                            <DisclosurePanel className="mt-2 space-y-2 bg-gray-100 items-center justify-center">
                                                {[...orderToAction].map((item) => (
                                                    <DisclosureButton
                                                        key={item.name}
                                                        as="a"
                                                        href={item.href}
                                                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-200 focus:ring-4 focus:ring-gray-300 transition duration-300 ease-in-out transform hover:scale-105 uppercase"
                                                    >
                                                        {item.name}
                                                    </DisclosureButton>
                                                ))}
                                            </DisclosurePanel>

                                        </>
                                    )}
                                </Disclosure>
                                {/* Accounts Option */}
                                <Disclosure as="div" className="-mx-3">
                                    {({ open }) => (
                                        <>
                                            <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 bg-gray-100 hover:bg-gray-200">
                                                <UserCircleIcon className="h-6 w-6" aria-hidden="true" /> My Accounts
                                                <ChevronDownIcon
                                                    className={classNames(open ? "rotate-180" : "", "h-5 w-5 flex-none")}
                                                    aria-hidden="true"
                                                />
                                            </DisclosureButton>
                                            <DisclosurePanel className="mt-2 space-y-2 bg-gray-100 items-center justify-center">
                                                {[...userToAction].map((item) => (
                                                    <DisclosureButton
                                                        key={item.name}
                                                        as="a"
                                                        href={item.href}
                                                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-200 focus:ring-4 focus:ring-gray-300 transition duration-300 ease-in-out transform hover:scale-105 uppercase"
                                                    >
                                                        {item.name}
                                                    </DisclosureButton>
                                                ))}
                                            </DisclosurePanel>

                                        </>
                                    )}
                                </Disclosure>
                                {/* Settings Option */}
                                <Disclosure as="div" className="-mx-3">
                                    {({ open }) => (
                                        <>


                                            <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 bg-gray-100 hover:bg-gray-200">
                                                <WrenchScrewdriverIcon className="h-6 w-6" aria-hidden="true" /> Settings

                                                <ChevronDownIcon
                                                    className={classNames(open ? "rotate-180" : "", "h-5 w-5 flex-none")}
                                                    aria-hidden="true"
                                                />
                                            </DisclosureButton>
                                            <DisclosurePanel className="mt-2 space-y-2 bg-gray-100 items-center justify-center">
                                                {[...settingToAction].map((item) => (
                                                    <DisclosureButton
                                                        key={item.name}
                                                        as="a"
                                                        href={item.href}
                                                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-200 focus:ring-4 focus:ring-gray-300 transition duration-300 ease-in-out transform hover:scale-105 uppercase"
                                                    >
                                                        {item.name}
                                                    </DisclosureButton>
                                                ))}
                                            </DisclosurePanel>

                                        </>
                                    )}
                                </Disclosure>
                            </div>

                            {/* Log Out Option */}
                            <div className="py-6 text-center">
                                <Link
                                    to="/login"
                                    className="p-4 w-full uppercase py-2 text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-300 font-semibold rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                                    onClick={handleLogout}
                                > Log out
                                </Link>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </header>
    );
}
