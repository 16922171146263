import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PaymentService from "../../../services/admin/payment.services"
import { toast } from "react-toastify";
import { useParams, useNavigate, Link } from "react-router-dom";
import { isEmpty } from "validator";


const initialValues = {
  id: 0,
  paymentMode: '',
  status: '',
};
const PaymentForm = () => {
  const params = useParams();
  const id = params.id;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialValues);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    paymentMode: Yup.string().required("Payment mode is required"),
    status: Yup.string().required("Status is required"),
  });


  const handleSubmit = async (values, { resetForm }) => {
    const data = {
      paymentMode: values.paymentMode,
      status: values.status,
    };
    setLoading(true);
    try {
      if ((!isEmpty(id)) && (id > 0)) {
        const response = await PaymentService.update(id, data);
        console.log(response, "####")
        if (response.data) {
          navigate("/admin/payment");
          toast.success("payment updated successfully!");
        } else {
          toast.error(response.message);
        }
      } else {
        const response = await PaymentService.create(data);
        if (response.data) {
          navigate("/admin/payment");
          toast.success("payment added successfully!");
          resetForm();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.error("Error submitting payment:", error);
      toast.error(id ? "Failed to update payment." : "Failed to create payment.");
    } finally {
      setLoading(false);
    }
  };

  const fetchPaymentData = async () => {
    try {
      if ((!isEmpty(id)) && (id > 0)) {
        const response = await PaymentService.findOne(id);
        console.log(response)
        if (response.status) {
          const data = response.data.data;
          setData({
            id: data.id,
            paymentMode: data.paymentMode,
            status: data.status,
          });
        } else {
          toast.error("Failed to load payment data.");
        }
      }

    } catch (error) {
      console.error("Error fetching payment data:", error);
      toast.error("Failed to load payment data.");
    }
  };

  useEffect(() => {
    fetchPaymentData();
  }, []);

  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>{(id > 0) ? "Edit Payment" : "Add Payment"}</h4>
          <h6>{(id > 0) ? "Update the payment details" : "Create a new payment"}</h6>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={data}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className="row align-top">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="paymentMode">
                        Payment Mode <span className="manitory">*</span>
                      </label>
                      <Field
                        as="select"
                        name="paymentMode"
                        className="form-control"
                        id="paymentMode"
                      >
                        <option value="">Select</option>
                        <option value="COD">COD</option>
                        <option value="ONLINE">ONLINE</option>
                      </Field>
                      <ErrorMessage
                        name="paymentMode"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>


                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <Field
                        as="select"
                        name="status"
                        className="form-control"
                        id="status"
                      >
                        <option value="">Choose Status</option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </Field>
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <button className="btn btn-submit me-2" type="submit" disabled={loading}>
                      {loading ? "Saving..." : (id > 0) ? "Update Payment" : "Add Payment"}
                    </button>
                    <Link to={"/admin/payment"} className="btn btn-cancel" type="reset">
                      Cancel
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
