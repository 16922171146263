import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';

const DeletePopup = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      role="dialog"
      className="text-center justify-center p-8"
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        {"Are you sure?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="text-center">
          You won't be able to revert this!
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', paddingBottom: '16px' }}>
        <button
          onClick={onConfirm}
          type="button"
          className="bg-orange-400 text-white px-6 py-2 rounded cursor-pointer mx-2"
        >
          Yes, delete it!
        </button>
        <button
          onClick={onClose}
          type="button"
          className="bg-red-600 text-white px-6 py-2 rounded cursor-pointer mx-2"
        >
          Cancel
        </button>
      </DialogActions>
    </Dialog>
  );
};

DeletePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DeletePopup;
