// NoAccessPage.js
import React from 'react';
import { Link } from 'react-router-dom';

const NoAccessPage = () => {
    return (
        <div style={{ textAlign: 'center', padding: '50px' }}>
            <h1>Access Denied</h1>
            <p>You do not have permission to view this page.</p>
            <p>
                If you believe this is a mistake, please contact support.
            </p>
            <Link to="/">Go to Home</Link>
        </div>
    );
};

export default NoAccessPage;
