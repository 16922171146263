import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom"; 
import OrderService from "../../../services/admin/order.services";
import DataTable from "../../../components/Datatable/DataTable" 
import { toast } from "react-toastify";

const Order = () => {
    const [data, setData] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState(""); 
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 }); 

  const fetchData = useCallback(async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const response = await OrderService.findAll(page, pageSize, searchTerm);
      console.log(response.data.data.rows, "Orders fetched");
      setData(response.data.data.rows); 
     
      setTotalCount(response.data.data.count); 
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [searchTerm, paginationModel]);

  useEffect(() => {
    fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
  }, [paginationModel, fetchData, searchTerm]);

 
  const columns = [
    {
      field: 'id',
      headerName: 'S.NO',
      width: 90,
      renderCell: (params) => {
        const { page, pageSize } = paginationModel;
        const rowIndex = (page * pageSize) + params.api.getAllRowIds().indexOf(params.id);
        return <strong>{rowIndex + 1}</strong>; 
      }
    },
    {
      field: 'orderNumber',
      headerName: 'Order Number',
      width: 150,
    },
    {
        field: 'fullName',
        headerName: 'Full Name',
        width: 180,
        renderCell: (params) => {
          return <span>{params.row.address_master.fullName}</span>;
        }
      },
      {
        field: 'phone',
        headerName: 'Phone',
        width: 180,
        renderCell: (params) => {
          return <span>{params.row.address_master.phone}</span>;
        }
      },
      {
        field: 'city',
        headerName: 'City',
        width: 150,
        renderCell: (params) => {
          return <span>{params.row.address_master.city}</span>;
        }
      },
      {
        field: 'district',
        headerName: 'District',
        width: 150,
        renderCell: (params) => {
          return <span>{params.row.address_master.district}</span>;
        }
      },
      {
        field: 'state',
        headerName: 'State',
        width: 150,
        renderCell: (params) => {
          return <span>{params.row.address_master.state}</span>;
        }
      },
      {
        field: 'pinCode',
        headerName: 'Pin Code',
        width: 120,
        renderCell: (params) => {
          return <span>{params.row.address_master.pinCode}</span>;
        }
      },
      {
        field: 'houseNo',
        headerName: 'House No',
        width: 120,
        renderCell: (params) => {
          return <span>{params.row.address_master.houseNo}</span>;
        }
      },
      {
        field: 'landmark',
        headerName: 'Landmark',
        width: 120,
        renderCell: (params) => {
          return <span>{params.row.address_master.landmark}</span>;
        }
      },
      {
        field: 'couponCode',
        headerName: 'Coupon Code',
        width: 150,
        renderCell: (params) => {
          return <span>{params.value}</span>;
        }
      },
    {
      field: 'shippingAddress',
      headerName: 'Shipping Address',
      width: 300,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      }
    },
    {
        field: 'shippingMethod',
        headerName: 'Shipping Method',
        width: 150,
        renderCell: (params) => {
          return <span>{params.value}</span>;
        }
      },
      {
        field: 'razorpayPaymentId',
        headerName: 'Razorpay PaymentId',
        width: 200,
        renderCell: (params) => {
          return <span>{params.value}</span>;
        }
      },
      {
        field: 'tax',
        headerName: 'Tax',
        width: 120,
        renderCell: (params) => {
          return <span>₹{params.value}</span>;
        }
      },
    {
      field: 'totalPrice',
      headerName: 'Total Price',
      width: 120,
      renderCell: (params) => {
        return <span>₹{params.value}</span>;
      }
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      width: 120,
    },
    {
      field: 'expectedDelivery',
      headerName: 'Expected Delivery',
      width: 180,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      }
    },
    {
      field: 'status',
      headerName: 'Order Status',
      width: 150,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      }
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      width: 150,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      }
    },
    {
      field: 'paymentMethod',
      headerName: 'Payment Method',
      width: 150,
      renderCell: (params) => {
        return <span>{params.value}</span>;
      }
    },
  ];
  
  


  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>Order List</h4>
          <h6>Manage your Orders</h6>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="table-top">
            <div className="search-set">
              <div className="search-input">
                <Link className="btn btn-searchset">
                  <img src="../../../assets/img/icons/search-white.svg" alt="search" />
                </Link>
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  <label>
                    <input
                      type="search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="form-control form-control-sm"
                      placeholder="Search..."
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
          <DataTable
                columns={columns}
                data={data}
                loading={loading}
                totalCount={totalCount}
                paginationModel={paginationModel}
                onPaginationModelChange={(model) => setPaginationModel(model)}
              />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
