import ApiInstance from "../../axios/api.auth";


const getProfile = () => {
  return ApiInstance.get("user/profile");
};

const UserService = {
  getProfile,
};

export default UserService;
