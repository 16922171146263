import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllOrder } from "../../../features/order.slice";

const MyOrderPage = () => {
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_API_URL;
  const { items, status, error } = useSelector((state) => state.order);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchAllOrder());
    }
  }, [status, dispatch]);
  return (
    <div className="page-wrapper ms-0 bg-gray-50">
      <div className="container mx-auto px-4 py-4">
        <h4 className="text-2xl font-semibold text-gray-800 mb-6">My Order</h4>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
          {/* Widget 1 - Order Summary */}
          <div className="bg-white p-3 rounded-lg shadow-sm">
            <div className="flex justify-center items-center mb-2">
              <img
                src="../../../assets/img/icons/dash1.svg"
                alt="Total Orders"
                className="w-6 h-6" // Smaller image size
              />
            </div>
            <div className="text-center">
              <h5 className="text-xl font-semibold text-blue-600">
                50000
              </h5>
              <h6 className="text-xs text-gray-500">Total Orders</h6>
            </div>
          </div>

          {/* Widget 2 - Pending Orders */}
          <div className="bg-white p-3 rounded-lg shadow-sm">
            <div className="flex justify-center items-center mb-2">
              <img
                src="../../../assets/img/icons/dash2.svg"
                alt="Pending Orders"
                className="w-6 h-6" // Smaller image size
              />
            </div>
            <div className="text-center">
              <h5 className="text-xl font-semibold text-blue-600">
                50000
              </h5>
              <h6 className="text-xs text-gray-500">Pending Orders</h6>
            </div>
          </div>

          {/* Widget 3 - Completed Orders */}
          <div className="bg-white p-3 rounded-lg shadow-sm">
            <div className="flex justify-center items-center mb-2">
              <img
                src="../../../assets/img/icons/dash3.svg"
                alt="Completed Orders"
                className="w-6 h-6" // Smaller image size
              />
            </div>
            <div className="text-center">
              <h5 className="text-xl font-semibold text-blue-600">
                50000
              </h5>
              <h6 className="text-xs text-gray-500">Completed Orders</h6>
            </div>
          </div>

          {/* Widget 4 - Canceled Orders */}
          <div className="bg-white p-3 rounded-lg shadow-sm">
            <div className="flex justify-center items-center mb-2">
              <img
                src="../../../assets/img/icons/dash4.svg"
                alt="Canceled Orders"
                className="w-6 h-6" // Smaller image size
              />
            </div>
            <div className="text-center">
              <h5 className="text-xl font-semibold text-blue-600">
                50000
              </h5>
              <h6 className="text-xs text-gray-500">Canceled Orders</h6>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
          {status === 'loading' && <p>Loading data...</p>}
          {status === 'failed' && <p>{error || 'Failed to load data.'}</p>}
          {status === 'succeeded' && (
            <>
              {(items.length > 0) && items ? (
                items.map((orderItem, index) => (
                  <div key={index} className="lg:col-span-2">
                    <div className="bg-white shadow-lg rounded-lg p-6 mb-6">

                      <div className="totalitem mb-2">
                        <p className="inline-block px-3  text-sm font-semibold text-white bg-gray-400 rounded-full hover:bg-gray-500 transition-colors duration-300">
                          <small>ORDER :<span className="font-semibold  text-white"> #{orderItem.orderNumber}</span></small>
                        </p>
                        <p className=" text-gray-600 uppercase">
                          <small>Order Placed :<span className="font-semibold  text-blue-400"> {new Date(orderItem.createdAt).toLocaleDateString()}</span></small>
                        </p>
                      </div>
                      <div className="mt-6">
                        <div className="flex flex-col sm:flex-row items-start border-b pb-4 mb-6">
                          <div className="flex-grow overflow-hidden">
                            <h6 className="font-semibold text-lg text-gray-800 truncate mt-2">
                              Shipping Address
                            </h6>
                            <div className="text-yellow-400 mt-2">
                              <p className="mt-2 text-gray-600"><span className="font-bold">{orderItem.address_master.fullName}</span></p>
                              <p className="mt-1 text-gray-600"><span className="font-medium">{orderItem.address_master.phone}</span></p>
                              <p className="text-gray-600">{orderItem.address_master.houseNo}{orderItem.address_master.landmark}</p>
                              <p className="text-gray-600">{orderItem.address_master.city}{orderItem.address_master.district}{orderItem.address_master.state}</p>
                              <p className="text-gray-600">{orderItem.address_master.pinCode} IN</p>
                            </div>
                          </div>
                          <div className="flex-grow overflow-hidden">
                            <h6 className="font-semibold text-lg text-gray-800 truncate mt-2">
                              Order Status
                            </h6>
                            <p className="mt-2 text-gray-600">Order Status : <span className="font-medium uppercase text-blue-400">{orderItem.status}</span></p>
                            <p className="mt-2 text-gray-600">Payment Status : <span className="font-medium uppercase text-green-400">{orderItem.paymentStatus}</span></p>
                            <p className="mt-2 text-gray-600">Payment Mode : <span className="font-medium uppercase text-orange-400">{orderItem.paymentMethod}</span></p>
                          </div>

                          <div className="flex-grow overflow-hidden">
                            <h6 className="font-semibold text-lg text-gray-800 truncate mt-2">
                              Expected Delivery
                            </h6>
                            <div className="text-yellow-400 mt-2">
                              <p className="mt-2 text-red-500"><span className="font-medium"> {orderItem.expectedDelivery}</span></p>
                            </div>
                            {orderItem.paymentMethod === 'ONLINE' ? (<>
                              <h6 className="font-semibold text-lg text-gray-800 truncate mt-4">
                                Transaction ID
                              </h6>
                              <div className="flex items-center mt-2">
                                <span className="text-sm font-semibold text-gray-900">{orderItem.razorpayPaymentId}</span>
                              </div></>
                            ) : (<></>)}
                          </div>
                        </div>
                        {orderItem.order_items.length > 0 ? (
                          orderItem.order_items.map((itm, i) => (
                            <div className="flex flex-col sm:flex-row items-start border-b pb-2 mb-3 divide-y divide-dashed" key={i}>
                              <div className="mr-6 mb-4 sm:mb-0">
                                <img
                                  crossOrigin="anonymous"
                                  src={`${BASE_URL}${itm.productSlug}?t=${new Date().getTime()}`}
                                  alt='Product img'
                                  className="rounded-md w-24 h-24 object-cover"
                                />
                              </div>
                              <div className="flex-grow overflow-hidden">
                                <h6 className="font-semibold text-lg text-gray-800 truncate">
                                  {itm.productName}
                                </h6>
                                <p className="mt-2 text-gray-600">Rate : <span className="font-medium">₹{itm.price} /{itm.unit}</span></p>
                                <p className="mt-2 text-gray-600">Quantity : <span className="font-medium">{itm.quantity}</span></p>
                                <p className="mt-2 text-gray-600">Sub Total : <span className="font-medium">₹{parseFloat((itm.subTotalPrice).toFixed(2))}</span></p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="flex-shrink-0" >No found product.</div>
                        )}
                      </div>
                      <h6 className="font-medium text-lg text-gray-600 truncate mt-2">
                        Total Amount:<span className="text-lg font-extrabold text-gray-600"> ₹{parseFloat((orderItem.totalPrice).toFixed(2))}</span>
                      </h6>
                      <div className="flex space-x-4 justify-center mt-8">
                        {orderItem.paymentMethod === 'ONLINE' ? (
                          <p className="px-6 py-2 uppercase  focus:outline-none text-green-500 focus:ring-4 focus:ring-gray-300 font-semibold rounded-lg transition duration-300 ease-in-out transform hover:scale-105">
                            PAYMENT SUCCESS
                          </p>
                        ) : (
                          <p className="px-6 py-2 uppercase  focus:outline-none focus:ring-4 focus:ring-gray-300 font-semibold rounded-lg transition duration-300 ease-in-out transform hover:scale-105">
                            Cash On Delivery
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No order data available!!</p>
              )}
            </>
          )}


        </div>
      </div>
    </div>
  );
};

export default MyOrderPage;
