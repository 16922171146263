import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import ExpanseService from "../../../services/admin/expanse.services"


const ExpanseForm = () => {
  const [loading, setLoading] = useState(false);
  const initialValues = {
    category: '',
    date: '',
    amount: '',
    referenceNo: '',
    expenseFor: '',
    description: '',
  };

  const validationSchema = Yup.object({
    category: Yup.string().required('Expense category is required'),
    date: Yup.string().required('Expense date is required'),
    amount: Yup.number().required('Amount is required').positive('Amount must be positive'),
    referenceNo: Yup.string().required('Reference number is required'),
    expenseFor: Yup.string().required('Expense for is required'),
    description: Yup.string().required('Description is required'),
  });


  const handleSubmit = (values, { resetForm }) => {
    const data = {
      paymentMode: values.paymentName,
    };
    try {
      setLoading(true);
      ExpanseService.create(data)
        .then((response) => {
          console.log(response);
          if (data.success) setLoading(false);
          toast.success(data.message);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      resetForm();
    } catch (error) {
      console.error("Error during API call", error);
      alert("An error occurred. Please try again.");
    }
  };
  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>Expense Add</h4>
          <h6>Add/Update Expenses</h6>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="category">Expense Category</label>
                      <Field as="select" name="category" className="form-control">
                        <option value="">Choose Category</option>
                        <option value="category1">Category 1</option>
                        <option value="category2">Category 2</option>
                      </Field>
                      <ErrorMessage name="category" component="div" className="text-danger" />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="date">Expense Date</label>
                      <div className="input-groupicon">
                        <Field
                          type="text"
                          name="date"
                          placeholder="Choose Date"
                          className="form-control datetimepicker"
                        />
                        <div className="addonset">
                          <img src="../../assets/img/icons/calendars.svg" alt="calendar icon" />
                        </div>
                      </div>
                      <ErrorMessage name="date" component="div" className="text-danger" />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="amount">Amount</label>
                      <div className="input-groupicon">
                        <Field type="text" name="amount" className="form-control" />
                        <div className="addonset">
                          <img src="../../assets/img/icons/dollar.svg" alt="dollar icon" />
                        </div>
                      </div>
                      <ErrorMessage name="amount" component="div" className="text-danger" />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="referenceNo">Reference No.</label>
                      <Field type="text" name="referenceNo" className="form-control" />
                      <ErrorMessage name="referenceNo" component="div" className="text-danger" />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="expenseFor">Expense for</label>
                      <Field type="text" name="expenseFor" className="form-control" />
                      <ErrorMessage name="expenseFor" component="div" className="text-danger" />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <Field as="textarea" name="description" className="form-control" />
                      <ErrorMessage name="description" component="div" className="text-danger" />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <button type="submit" className="btn btn-submit me-2" disabled={loading}>
                      Submit
                    </button>
                    <button type="reset" className="btn btn-cancel">
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ExpanseForm;
