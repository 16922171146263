import ApiInstance from "../../axios/api.auth";

const getAll = async() => {
  return await ApiInstance.get("/user/cart/items/");
};

const create = async(data) => {
  return await ApiInstance.post("/user/cart/add/", data);
};
const destroy = async(id) => {
  return await ApiInstance.delete(`/user/cart/remove/` + id);
};

const update = async(data) => {
  return await ApiInstance.put("/user/cart/update/", data);
};

const CartService = {
  getAll,
  create,
  update,
  destroy
};

export default CartService;

