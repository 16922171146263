/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllBanner } from '../../../features/banners.slice';

const Carousel = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const { banner, status, error } = useSelector((state) => state.banner);
  // console.log(banner,"banner")
  const dispatch = useDispatch();

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchAllBanner()); 
    }
  }, [status, dispatch]);
  return (
    <>
      <div className="row">
        {status === 'loading' &&
          <div className="flex space-x-4 justify-center">
         
          {Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className="w-100 h-45 bg-gray-300 animate-pulse rounded-md" />
          ))}
        </div>
        }
        {status === 'failed' && (
          <div>
            <p>Error: {error}</p>
            {error.includes('404') && <p>The carousel you are looking for are not available.</p>}
          </div>
        )}
        {status === 'succeeded' && (<>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            className='swiper'
          >

            {banner.length > 0 ? (
              banner.map((bannerItem) => (
                <SwiperSlide key={bannerItem.id}>
                  <img
                    crossOrigin="anonymous"
                    src={`${BASE_URL}${bannerItem.attachmentName}`}
                    alt={`Image of ${bannerItem.title}`}
                    className="object-cover swiper-slide"
                  />
                </SwiperSlide>
              ))
            ) : (
              <li className="flex-shrink-0" >No categories available.</li>
            )}
          </Swiper>
        </>)}
      </div>
    </>
  );
}
export default Carousel;




