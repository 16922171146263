
import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import ProductService from "../../../services/admin/product.services";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCity, fetchAllDistrict, fetchAllState } from "../../../features/location.slice";

const initialValues = {
    product: "",
    state: "",
    district: "",
    cities: [], 
};

const LocationForm = () => {
    const [selectedCities, setSelectedCities] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const { stateData, districtData, cityData, statusState, statusDistrict, statusCity, error } = useSelector((state) => state.location);

    const validationSchema = Yup.object({
        product: Yup.string().required("Product is required"),
        state: Yup.string().required("State is required"),
        district: Yup.string().required("District is required"),
        cities: Yup.array().min(1, "At least one city must be selected").required("Cities are required"), 
    });

    const handleSubmit = (values, { resetForm }) => {
        console.log(values);
    };

    useEffect(() => {
        if (statusState === "idle") {
            dispatch(fetchAllState());
        }
    }, [dispatch, statusState]);

    const handleStateChange = (e, setFieldValue) => {
        const selectedState = e.target.value;
        setFieldValue("state", selectedState);
        setFieldValue("district", "");
        setFieldValue("cities", []); 
        if (selectedState) {
            dispatch(fetchAllDistrict({ stateName: selectedState }));
        }
    };

    const handleDistrictChange = (e, setFieldValue) => {
        const selectedDistrict = e.target.value;
        setFieldValue("district", selectedDistrict);
        setFieldValue("cities", []); 
        if (selectedDistrict) {
            dispatch(fetchAllCity({ districtName: selectedDistrict }));
        }
    };

    const handleCityChange = (e, setFieldValue) => {
        const cityName = e.target.value;
        const newSelectedCities = [...selectedCities];
        if (e.target.checked) {
            newSelectedCities.push(cityName);
        } else {
            const index = newSelectedCities.indexOf(cityName);
            if (index > -1) {
                newSelectedCities.splice(index, 1);
            }
        }
        setSelectedCities(newSelectedCities);
        setFieldValue("cities", newSelectedCities); 
    };

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await ProductService.findAllProduct();
            setProducts(response.data.data.rows);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className="content">
            <div className="page-header"></div>
            <div className="card">
                <div className="card-body">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ setFieldValue, values }) => (
                            <Form>
                                <div className="row">
                        
                                    <div className="col-lg-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <label htmlFor="product">
                                                Product <span className="manitory">*</span>
                                            </label>
                                            <Field
                                                as="select"
                                                name="product"
                                                className="form-control"
                                            >
                                                <option value="">Select Product</option>
                                                {products.map((product) => (
                                                    <option key={product.id} value={product.productName}>{product.productName}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage
                                                name="product"
                                                component="div"
                                                className="text-danger"
                                            />
                                        </div>
                                    </div>

                                 
                                    <div className="col-lg-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <label htmlFor="state">State <span className="manitory">*</span></label>
                                            <Field as="select" name="state" className="form-control" id="state" value={values.state} onChange={(e) => handleStateChange(e, setFieldValue)}>
                                                <option value="">Select State</option>
                                                {stateData.map((item, index) => (
                                                    <option key={index} value={item.stateName}>{item.stateName}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="state" component="div" className="text-danger" />
                                        </div>
                                    </div>

                                   
                                    <div className="col-lg-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <label htmlFor="district">District <span className="manitory">*</span></label>
                                            <Field as="select" name="district" className="form-control" id="district" value={values.district} onChange={(e) => handleDistrictChange(e, setFieldValue)}>
                                                <option value="">Select District</option>
                                                {districtData.map((item, index) => (
                                                    <option key={index} value={item.distName}>{item.distName}</option>
                                                ))}
                                            </Field>
                                            <ErrorMessage name="district" component="div" className="text-danger" />
                                        </div>
                                    </div>

                                
                                    <div className="col-lg-6 col-sm-6 col-12">
                                        <div className="form-group">
                                            <label htmlFor="cities">Cities <span className="manitory">*</span></label>
                                            <div id="cities">
                                                {cityData.map((city, index) => (
                                                    <div key={index} className="form-check">
                                                        <input
                                                            type="checkbox"
                                                            id={`city-${city.areaName}`}
                                                            name="cities"
                                                            value={city.areaName}
                                                            checked={selectedCities.includes(city.areaName)}
                                                            onChange={(e) => handleCityChange(e, setFieldValue)}
                                                            className="form-check-input"
                                                        />
                                                        <label htmlFor={`city-${city.areaName}`} className="form-check-label">
                                                            {city.areaName}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                            <ErrorMessage name="cities" component="div" className="text-danger" />
                                        </div>
                                    </div>

                                </div>

                             
                                <div className="col-lg-12">
                                    <button className="btn btn-submit me-2" type="submit" disabled={loading}>
                                        {"Add location"}
                                    </button>
                                    <Link to={"/admin/location"} className="btn btn-cancel" type="reset">
                                        Cancel
                                    </Link>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default LocationForm;



