import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchCategories } from '../../../features/category.slice';
import { useDispatch, useSelector } from 'react-redux';

const Marquees = () => {
    const { categories, status, error } = useSelector((state) => state.categories);
    const dispatch = useDispatch();

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchCategories());  
        }
    }, [status, dispatch]);
    return (
        <div className="row justify-center">
            {status === 'loading' &&
                <div className="space-x-4 flex">
                  
                    <div className="w-25 h-5 bg-gray-300 rounded-md animate-pulse"></div>
                    <div className="w-25 h-5 bg-gray-300 rounded-md animate-pulse"></div>
                    <div className="w-25 h-5 bg-gray-300 rounded-md animate-pulse"></div>
                    <div className="w-25 h-5 bg-gray-300 rounded-md animate-pulse"></div>
                    <div className="w-25 h-5 bg-gray-300 rounded-md animate-pulse"></div>
                    <div className="w-25 h-5 bg-gray-300 rounded-md animate-pulse"></div>
                </div>}
            {status === 'failed' && (
                <div>
                    <p>Error: {error}</p>
                    {error.includes('404') && <p>The categories you are looking for are not available.</p>}
                </div>
            )}
            {status === 'succeeded' && (<>
                <div className="overflow-hidden whitespace-nowrap justify-center items-center hidden lg:flex">
                    <div className="inline-block animate-marquee text-xl font-bold">
                        <div className="btn-pos uppercase">
                            <ul>
                                {categories.length > 0 ? (
                                    categories.map((category, index) => (
                                        <li className="flex-shrink-0" key={index} >
                                            <Link className="btn hover:text-red-500 transition duration-300">
                                                {category.categoriesName}
                                            </Link>
                                        </li>
                                    ))
                                ) : (
                                    <li className="flex-shrink-0" >No categories tag available.</li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </>)}
        </div>
    );
};

export default Marquees;
