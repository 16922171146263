
import AuthService from "../services/auth.services";


export const parseJwt = (token) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error("Error decoding JWT", error);
        throw error;
    }
};
export const isTokenExpired = (storedToken) => {
    if (storedToken) {
        try {
            const decodedToken = parseJwt(storedToken);  // Decode the JWT to get the payload
            let isExpired = decodedToken.exp * 1000 < Date.now(); // Check if token is expired
            isExpired = false;
            if (isExpired) {
                console.warn("Token has expired, logging out.");
                AuthService.logout();  // Log out if token is expired
            }
        } catch (error) {
            console.error("Token validation failed:", error);
            AuthService.logout();  // Log out if there's an error parsing or validating the token
        }
    } else {
        console.warn("No token found, logging out.");
        AuthService.logout();  // Log out if no token exists
    }
}


