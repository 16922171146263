import ApiInstance from "../../axios/api.auth";

const findAll = async(page, size, search) => {
  return await ApiInstance.get(`admin/expanse?page=${page}&size=${size}&search=${search}`);
};
const findOne = async (id) => {
  return await ApiInstance.get("admin/expanse/" + id);
};
const create = async(data) => {
  return await ApiInstance.post("admin/expanse", data);
};
const destroy = async(id) => {
  return await ApiInstance.delete("admin/expanse/" + id);
};

const update = async(id) => {
  return await ApiInstance.put("admin/expanse/" + id);
};

const ExpanseService = {
  findAll,
  findOne,
  create,
  update,
  destroy,
};

export default ExpanseService;
