import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import OrderService from '../services/user/order.services'; // Ensure this path is correct

// Async thunk to create a new order
export const createOrder = createAsyncThunk(
    'order/createOrder',
    async (requestData, { rejectWithValue }) => {
        try {
            const response = await OrderService.createOrder(requestData);
            return response.data.data;  // Assuming response contains data in 'data' field
        } catch (error) {
            return rejectWithValue(error.message);  // Handling errors
        }
    }
);

// Async thunk to fetch all orders
export const fetchAllOrder = createAsyncThunk(
    'order/fetchAllOrder',
    async (_, { rejectWithValue }) => {
        try {
            const response = await OrderService.getAllOrder();
            return response.data.data; // Return the list of orders
        } catch (error) {
            return rejectWithValue(error.message); // Handle errors
        }
    }
);

// Async thunk to fetch a single order by ID
export const fetchOrder = createAsyncThunk(
    'order/fetchOrder',
    async (orderId, { rejectWithValue }) => {
        try {
            const response = await OrderService.fetchOrder(orderId);
            return response.data.data; // Return the single order
        } catch (error) {
            return rejectWithValue(error.message); // Handle errors
        }
    }
);

// Slice for managing order state
const orderSlice = createSlice({
    name: 'order',
    initialState: {
        items: [],
        item: null, // Initialize as null to indicate no data yet
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null, // Will hold error messages if any
    },
    reducers: {
        // clearError: (state) => {
        //     state.error = null; // Action to clear error messages
        // },
    },
    extraReducers: (builder) => {
        builder
            // Create Order
            .addCase(createOrder.pending, (state) => {
                state.status = 'loading';  // Set status to loading while fetching
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                state.status = 'succeeded';  // Set status to succeeded after successful fetch
                state.item = action.payload;  // Store the created order response
            })
            .addCase(createOrder.rejected, (state, action) => {
                state.status = 'failed';  // Set status to failed if the fetch fails
                state.error = action.payload || action.error.message;  // Store the error message
            })
            // Fetch All Orders
            .addCase(fetchAllOrder.pending, (state) => {
                state.status = 'loading'; // Set loading status
            })
            .addCase(fetchAllOrder.fulfilled, (state, action) => {
                state.status = 'succeeded'; // Set succeeded status
                state.items = action.payload; // Store the list of orders
            })
            .addCase(fetchAllOrder.rejected, (state, action) => {
                state.status = 'failed'; // Set failed status
                state.error = action.payload || action.error.message; // Handle error
            })
            // Fetch Single Order
            .addCase(fetchOrder.pending, (state) => {
                state.status = 'loading'; // Set loading status
            })
            .addCase(fetchOrder.fulfilled, (state, action) => {
                state.status = 'succeeded'; // Set succeeded status
                state.item = action.payload; // Store the fetched single order
            })
            .addCase(fetchOrder.rejected, (state, action) => {
                state.status = 'failed'; // Set failed status
                state.error = action.payload || action.error.message; // Handle error
            });
    },
});

// export const { clearError } = orderSlice.actions;

export default orderSlice.reducer;
