import ApiInstance from "../../axios/api.auth";

const findAll = async(page, size, search) => {
  return await ApiInstance.get(`admin/taxes?page=${page}&size=${size}&search=${search}`);
};
const findAllTax = async() => {
  return await ApiInstance.get(`admin/taxes`);
};
const findOne = async (id) => {
  return await ApiInstance.get("admin/taxes/" + id);
};
const create = async(data) => {
  return await ApiInstance.post("admin/taxes",data);
};
const destroy = async(id) => {
  return await ApiInstance.delete("admin/taxes/" + id);
};

const update = async(id, data) => {
  return await ApiInstance.put("admin/taxes/" + id, data);
};

const TaxService = {
  findAll,
  findOne,
  create,
  update,
  destroy,
  findAllTax,
};

export default TaxService;
