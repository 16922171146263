import React from 'react';

const Snackbar = ({ message, visible, onClose }) => {
    return (
        <div
            className={`fixed bottom-5 right-5 p-4 text-white bg-gray-600 rounded-lg transition-opacity duration-300 ${visible ? 'opacity-100' : 'opacity-0'}`}
            style={{ display: visible ? 'block' : 'none' }}
        >
            {message}
            <button onClick={onClose} className="ml-4 text-red-400">Close</button>
        </div>
    );
};

export default Snackbar;
