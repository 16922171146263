import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectCurrentUser } from "../features/auth.slice";

const ProtectedRoute = ({ element, role }) => {
  const user = useSelector(selectCurrentUser);

  //If the user is not authenticated, redirect to login
  if (user == null) {
    return <Navigate to="/login" />;
  }

  // If the user does not have the required role, redirect to an access denied page
  if (role && user?.role !== role) {
    return <Navigate to="/no-access" />;
  }

  // If the user is authenticated and has the correct role, render the protected element
  return element;
};

export { ProtectedRoute };
