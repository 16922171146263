import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import paymentService from "../services/user/payment.services";

// Async thunks for fetching data
export const fetchAllPayment = createAsyncThunk(
  "payment/fetchAllPayment", // The slice name should match the logic
  async (_, { rejectWithValue }) => {
    try {
      const response = await paymentService.findAllPayment();
      // console.log(response,"responsepayment")
      return response.data.data.rows; // Assuming the response structure
    } catch (error) {
      // Handle errors and return a custom error message
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

// Slice
const paymentSlice = createSlice({
  name: "payment", // Make sure the name corresponds to the slice of state
  initialState: {
    items: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPayment.pending, (state) => {
        state.status = 'loading'; // Set status to loading while fetching
      })
      .addCase(fetchAllPayment.fulfilled, (state, action) => {
        state.status = 'succeeded'; // Set status to succeeded after successful fetch
        state.items = action.payload; // Store fetched payment items in state
      })
      .addCase(fetchAllPayment.rejected, (state, action) => {
        state.status = 'failed'; // Set status to failed if the fetch fails
        state.error = action.payload || action.error.message; // Store error message
      });
  },
});

export default paymentSlice.reducer;
