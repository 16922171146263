import ApiInstance from "../../axios/api.auth";


const findAllPayment = async () => {
    return await ApiInstance.get(`user/payments`);
};
const paymentInitiate = async (data) => {
    return await ApiInstance.post('user/payment-initiate', data);
};

const paymentVerify = async (data) => {
    return await ApiInstance.post('user/payment-verify', data);
};
const PaymentService = {

    findAllPayment,
    paymentInitiate,
    paymentVerify

};

export default PaymentService;
