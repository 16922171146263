import React, { useState, useEffect, useCallback } from "react";
import { format } from 'date-fns';

import DiscountService from "../../../services/admin/discount.services";
import DataTable from "../../../components/Datatable/DataTable"
import { Link } from "react-router-dom";
import DeletePopup from "../../../components/popups/DeletePopup"
import { toast } from "react-toastify";
import { PencilIcon , TrashIcon } from "@heroicons/react/20/solid";


const Discount = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const columns = [
    {
      field: 'id',
      headerName: 'S.NO',
      width: 90,
      renderCell: (params) => {
        const { page, pageSize } = paginationModel;
        const rowIndex = (page * pageSize) + params.api.getAllRowIds().indexOf(params.id);
        return <strong>{rowIndex + 1}</strong>; 
      }
    },
    { field: 'code', headerName: 'Code', width: 200 },
    { field: 'percentage', headerName: 'Percentage(%)', width: 200 },
    { field: 'amount', headerName: 'Amount', width: 200 },
    {
      headerName: 'Start Date',
      field: 'startDate',
      width: 200,
      renderCell: ({ value }) => format(new Date(value), 'yyyy-MM-dd'), 
    },
    {
      headerName: 'End Date',
      field: 'endDate',
      width: 200,
      renderCell: ({ value }) => format(new Date(value), 'yyyy-MM-dd'), 
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => {
        const status = params.value ? 'Active' : 'Inactive';
        const badgeClass = params.value ? 'badge' : 'badge'; 

        const badgeStyle = {
          padding: '5px 10px',
          fontSize: '12px',
          backgroundColor: params.value ? '#90EE90' : '#FFCCCB', 
          color: params.value ? '#006400' : '#8B0000', 
        };

        return (
          <span className={badgeClass} style={badgeStyle}>
            {status}
          </span>
        );
      }
    },
    {
      field: 'action', headerName: 'Action', width: 200, renderCell: (params) => (
        <div className="flex mt-3">
          <Link className="me-3" to={`edit/${params.row.id}`}>
          <PencilIcon className="h-5 w-5 text-green-600"/>
          </Link>
          <Link className="me-3 confirm-text" onClick={() => handleDelete(params.row.id)}>
          <TrashIcon className="h-5 w-5 text-red-600"/>
          </Link>
        </div>
      ),
    },
  ];

  const fetchData = useCallback(async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const response = await DiscountService.findAll(page, pageSize, searchTerm);
      setData(response.data.data.rows);
      setTotalCount(response.data.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
  }, [paginationModel, fetchData, searchTerm]);


  const handleDelete = async (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await DiscountService.destroy(deleteId);
      handleCloseDialog();
      toast.success("The discount has been deleted!");
      fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
    } catch (error) {
      toast.error('Error deleting discount: ' + error);
      console.error('Error deleting discount:', error);
    }
  };

  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Discount</h4>
            <h6>Manage Discount</h6>
          </div>
          <div className="page-btn">
            <Link to={"add"} className="btn btn-added" data-bs-toggle="modal" data-bs-target="#addpayment"><img src="../../../assets/img/icons/plus.svg" alt="img" className="me-1" />Add New Discount</Link>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <Link className="btn btn-searchset"><img src="../../../assets/img/icons/search-white.svg" alt="img" /></Link>
                  <div id="DataTables_Table_0_filter" className="dataTables_filter"><label>
                    <input type="search" value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)} className="form-control form-control-sm" placeholder="Search..." /></label></div></div>
              </div>
            </div>
            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={data}
                loading={loading}
                totalCount={totalCount}
                paginationModel={paginationModel}
                onPaginationModelChange={(model) => setPaginationModel(model)}
              />
            </div>
            <DeletePopup
              open={openDialog}
              onClose={handleCloseDialog}
              onConfirm={handleConfirmDelete}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Discount
