import React, { useState } from "react";
import { Link } from "react-router-dom";
import AdminService from "../../../services/admin/admin.services";
import { useEffect } from "react";
/* eslint-disable-next-line react/jsx-no-bind */

const Profile = () => {
  const [profile, setProfile] = useState({});


  const fetchData =  async() => {
    try {
      const response = await AdminService.getProfile();
      // console.log(response, "Profile Response:")
      setProfile(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    <div className="page-wrapper ms-0">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Profile</h4>
            <h6>User Profile</h6>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="profile-set">
              <div className="profile-head" />
              <div className="profile-top">
                <div className="profile-content">
                  <div className="profile-contentimg">
                    <img
                      src="../../../assets/img/customer/customer5.jpg"
                      alt="img"
                      id="blah"
                    />
                    <div className="profileupload">
                      <input type="file" id="imgInp" />
                      <Link to="#">
                        <img src="../../../assets/img/icons/edit-set.svg" alt="img" />
                      </Link>
                    </div>
                  </div>
                  <div className="profile-contentname">
                    <h2>{profile.openId}</h2>
                    <h4>{profile.role}</h4>
                  </div>
                </div>
                <div className="ms-auto">
                  <Link to="#" className="btn btn-submit me-2">
                    Save
                  </Link>
                  <Link to="#" className="btn btn-cancel">
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>First Name</label>
                  <input type="text" placeholder="William" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>Last Name</label>
                  <input type="text" placeholder="Castilo" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>Email</label>
                  <input type="text" placeholder="william@example.com" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>Phone</label>
                  <input type="text" placeholder="+1452 876 5432" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>User Name</label>
                  <input type="text" placeholder="+1452 876 5432" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>Password</label>
                  <div className="pass-group">
                    <input type="password" className=" pass-input" />
                    <span className="fas toggle-password fa-eye-slash" />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <Link to="#" className="btn btn-submit me-2">
                  Submit
                </Link>
                <Link to="#" className="btn btn-cancel">
                  Cancel
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Profile;
