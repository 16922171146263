import React, { useState, useRef } from "react";

const OtpInput = ({ length = 6, onChange }) => {
  const [otpValues, setOtpValues] = useState(Array(length).fill(""));
  const otpRefs = useRef([]);

  const handleChange = (index, value) => {
    if (!isNaN(value)) {
      const newValues = [...otpValues];
      newValues[index] = value;
      setOtpValues(newValues);
      // Joining the OTP values array into a string and passing it to parent component
      onChange(newValues.join(""));
      // Move focus to the next input box if it's not the last box
      if (otpRefs.current[index + 1] && value !== "") {
        otpRefs.current[index + 1].focus();
      }
    }
    // Handle backspace to move focus to the previous input box
    if (value === "" && index > 0) {
      otpRefs.current[index - 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    // Prevent default behavior for backspace key press
    if (event.keyCode === 8) {
      event.preventDefault();
      handleChange(index, "");
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData
      .getData("text/plain")
      .trim()
      .slice(0, length);
    const pasteArray = pasteData
      .split("")
      .filter((char) => !isNaN(char) && char !== " ")
      .slice(0, length);
    let newValues = [...otpValues];
    pasteArray.forEach((value, index) => {
      newValues[index] = value;
    });
    setOtpValues(newValues);
    onChange(newValues.join(""));
  };

  return (
    <div className="flex flex-row justify-center text-center px-2 mt-5 otp-input-container">
      {otpValues.map((value, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          className="m-2 border h-10 w-10 text-center form-control rounded p-2"
          value={value}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          onPaste={(e) => handlePaste(e)}
          ref={(ref) => otpRefs.current.push(ref)}
          autoFocus={index === 0}
        />
      ))}
    </div>
  );
};

export default OtpInput;
