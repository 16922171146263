import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import locationService from "../services/admin/location.services";

// Async thunks for fetching data
export const fetchAllState = createAsyncThunk("location/fetchAllState", async () => {
  const response = await locationService.findAllState();
  return response.data.data;
});

export const fetchAllDistrict = createAsyncThunk("location/fetchAllDistrict", async ({ stateName }) => {
  const response = await locationService.findAllDistrictByStateName(stateName);
  return response.data.data;
});

export const fetchAllCity = createAsyncThunk("location/fetchAllCity", async ({ districtName }) => {
  const response = await locationService.findAllCityByDistrictName(districtName);
  return response.data.data;
});

// Slice
const locationSlice = createSlice({
  name: "location",
  initialState: {
    stateData: [],
    districtData: [],
    cityData: [],
    statusState: "idle",
    statusDistrict: "idle",
    statusCity: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllState.pending, (state) => {
        state.statusState = "loading";
      })
      .addCase(fetchAllState.fulfilled, (state, action) => {
        state.stateData = action.payload;
        state.statusState = "succeeded";
        state.districtData = [];
        state.cityData = [];
      })
      .addCase(fetchAllDistrict.fulfilled, (state, action) => {
        state.districtData = action.payload;
        state.statusDistrict = "succeeded";
        state.cityData = [];
      })
      .addCase(fetchAllCity.fulfilled, (state, action) => {
        state.cityData = action.payload;
        state.statusCity = "succeeded";
      });
  },
});

export default locationSlice.reducer;
