import ApiInstance from "../../axios/api.auth";


const createOrder = async(data) => {
  return await ApiInstance.post("user/orders/create", data);
};
const getAllOrder = async() => {
  return await ApiInstance.get("user/orders");
};
const fetchOrder = async(orderId) => {
  return await ApiInstance.get(`user/orders/${orderId}`);
};

const OrderServices = {
    createOrder,
    getAllOrder,
    fetchOrder
};

export default OrderServices;

