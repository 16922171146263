import ApiInstance from "../../axios/api.auth";

const findAll = async(page, size, search) => {
  return await ApiInstance.get(`admin/products?page=${page}&size=${size}&search=${search}`);
};

const findAllProduct = async() => {
  return await ApiInstance.get(`admin/products`);
}; 
const findOne = async (id) => {
  return await ApiInstance.get("admin/products/" + id);
};
const create = async(data) => {
  return await ApiInstance.post("admin/products", data);
};
const destroy = async(id) => {
  return await ApiInstance.delete("admin/products/" + id);
};

const update = async(id, data) => {
  return await ApiInstance.put("admin/products/" + id, data);
};

const ProductService = {
  findAll,
  findAllProduct,
  findOne,
  create,
  update,
  destroy,
};

export default ProductService;
