import React, { useState } from 'react';
import Header from './../../user/layout/include/Header';
import Footer from './../../user/layout/include/Footer';
import Marquees from '../../user/marquee';
import Category from "./../../user/category";

import Products from '../../user/products';
import Carousel from '../../user/carousel';
import { Snackbar } from '@mui/material';

const Home = () => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  return (
    <div className="flex flex-col h-screen main-wrapper">
      <div className="header">
        <Header />
      </div>
      <div className="flex flex-1 overflow-hidden">
        <main className="flex-1 overflow-y-auto">
          <div className="page-wrapper ms-0">
            <div className="content">
              <Marquees />
              <Carousel />
            </div>
            <div className="content">
              <Category />
            </div>
            <div className="content">
            <Products setSnackbarMessage={setSnackbarMessage} setSnackbarVisible={setSnackbarVisible} />
            </div>
            <Snackbar message={snackbarMessage} visible={snackbarVisible} onClose={() => setSnackbarVisible(false)} />
          </div>
        </main>
      </div>
      <Footer />
    </div>

  );
};

export default Home;
