import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../../features/auth.slice";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { removeFromCartAPI, updateCartAPI, fetchAllCartAPI } from "../../../features/cart.slice";
import Snackbar from "../../../components/Snackbar/Snackbar";
import DeliveryService from "../../../services/user/delivery.services";

const CartPage = () => {
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.items);
    const cartStatus = useSelector((state) => state.cart.status);
    const totalPrice = useSelector((state) => state.cart.totalPrice);
    const user = useSelector(selectCurrentUser);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [pincode, setPinCode] = useState("");
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();


    useEffect(() => {
       
        if (cartStatus === "idle") {
            dispatch(fetchAllCartAPI());  
        }
    }, [dispatch, cartStatus]);



    useEffect(() => {
        if (snackbarVisible) {
            const timer = setTimeout(() => setSnackbarVisible(false), 3000);
            return () => clearTimeout(timer);
        }
    }, [snackbarVisible]);

  
    if (!user) {
        return <Navigate to="/login" />;
    }






    const handleQuantityChange = (productId, quantityChange) => {
        const newQuantity = Math.max(1, quantityChange);
        dispatch(updateCartAPI({ productId, quantity: newQuantity }))
            .unwrap()
            .then(() => setSnackbarMessage('Item quantity updated!'))
            .catch(() => setSnackbarMessage('Failed to update item quantity.'));
        setSnackbarVisible(true);
    };

 
    const handleDelete = (productId) => {
        dispatch(removeFromCartAPI(productId))
            .unwrap()
            .then(() => setSnackbarMessage('Item removed from cart.'))
            .catch(() => setSnackbarMessage('Failed to remove item from cart.'));
        setSnackbarVisible(true);
    };

   
    const handleCheckPin = async () => {
        if (!pincode.match(/^\d{6}$/)) {
            setError("Please enter a valid 6-digit PIN code.");
            return;
        }
        try {
            if (!cartItems.length) {
                setError('Your cart is empty.');
                return;
            }
            const results = await Promise.all(
                cartItems.map((item) => DeliveryService.checkPin(item.productId, pincode))
            );
            const allDeliverable = results.every((res) => res.data.data.deliveryAvailable);
            if (allDeliverable) {
                setSuccess("Delivery is available.");
                setError(""); 
            } else {
                setError("Delivery is not available for this product in the given PIN code.");
            }
        } catch (err) {
            setError("An error occurred while checking delivery.");
        }
    };


    const handlePlaceOrder = async () => {
        if (!cartItems.length) {
            setSnackbarMessage('Your cart is empty.');
            setSnackbarVisible(true);
            return;
        }
        if (!pincode.match(/^\d{6}$/)) {
            setError("Please enter a valid 6-digit PIN code.");
            return;
        }
        const results = await Promise.all(
            cartItems.map((item) => DeliveryService.checkPin(item.productId, pincode))
        );
        const allDeliverable = results.every((res) => res.data.data.deliveryAvailable);
        if (allDeliverable) {
            navigate('/user/order', { state: { pincode } });
        } else {
            setError("Delivery is not available for this product in the given PIN code.");
        }
    };



    const discountedPrice = totalPrice - 0; 
    const taxRate = 0.14;

    const totalTax = cartItems.reduce((total, item) => total + (item.price * item.quantity * taxRate), 0);

    return (
        <div className="page-wrapper ms-0 bg-gray-50">
            <div className="content px-6 py-8">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                    <div className="lg:col-span-2 bg-white shadow-md rounded-lg">
                        <div className="px-6 py-6">
                            <h4 className="text-2xl font-semibold text-gray-800 mb-6">Your Shopping Cart</h4>

                        
                            {cartStatus === 'loading' && Array(cartItems.length).fill().map((_, index) => (
                                <div key={index} className="flex mb-4 justify-between border-b py-4 animate-pulse">
                                    <div className="w-24 h-24 bg-gray-300 rounded-md" />
                                    <div className="flex-1 space-y-2">
                                        <div className="h-6 bg-gray-300 rounded-md w-3/4" />
                                        <div className="h-4 bg-gray-300 rounded-md w-1/2" />
                                        <div className="h-4 bg-gray-300 rounded-md w-1/3" />
                                        <div className="h-4 bg-gray-300 rounded-md w-1/3" />
                                    </div>
                                </div>
                            ))}

                         
                            {cartItems.length === 0 ? (
                                <div className="text-center p-6 text-gray-500">
                                    <p>Your cart is empty.</p>
                                </div>
                            ) : (
                                <>
                                    <div className="totalitem mb-6">
                                        <h4 className="text-lg font-medium text-gray-700">Total items: {cartItems.length}</h4>
                                    </div>
                                    {cartItems.map((item, index) => (
                                        <div key={index} className="flex flex-col sm:flex-row items-center justify-between border-b py-4">
                                            <img
                                                crossOrigin="anonymous"
                                                src={`${process.env.REACT_APP_API_URL}/${item.productSlug}?t=${new Date().getTime()}`}
                                                alt={item.name}
                                                className="w-20 h-20 object-cover rounded-md sm:w-24 sm:h-24"
                                            />
                                            <div className="flex-1 pl-5 mt-4 sm:mt-0">
                                                <p className="font-medium text-lg text-gray-800">{item.name}</p>
                                                <p className="text-sm text-gray-500">Rate: ₹{item.price} /{item.unit}</p>
                                                <p className="text-sm text-gray-500">Quantity: <span className="text-red-500 font-bold">{item.quantity}</span></p>
                                                <p className="text-gray-500 text-sm">Sub Total: ₹{(item.price * item.quantity).toFixed(2)}</p>
                                            
                                                <p className="text-gray-500 text-sm">Tax (14%): ₹{(item.price * item.quantity * 0.14).toFixed(2)}</p>
                                            </div>
                                            <div className="flex items-center gap-4 mt-4 sm:mt-0">
                                                <div className="flex items-center space-x-2 border border-gray-300 rounded-md overflow-hidden">
                                                    <button
                                                        onClick={() => handleQuantityChange(item.productId, item.quantity - 1)}
                                                        disabled={item.quantity <= 1}
                                                        className={`text-sm bg-gray-200 px-4 py-2 ${item.quantity <= 1 ? 'bg-gray-400' : ''}`}
                                                    >
                                                        <img src="../../../assets/img/icons/minus.svg" alt="Decrease" className="w-4 h-4" />
                                                    </button>
                                                    <span className="text-sm px-4">{item.quantity}</span>
                                                    <button
                                                        onClick={() => handleQuantityChange(item.productId, item.quantity + 1)}
                                                        className="text-sm bg-gray-200 px-4 py-2"
                                                    >
                                                        <img src="../../../assets/img/icons/plus.svg" alt="Increase" className="w-4 h-4" />
                                                    </button>
                                                </div>
                                                <button
                                                    onClick={() => handleDelete(item.productId)}
                                                    className=" uppercase  text-orange-500 focus:outline-none focus:ring-4 focus:ring-orange-300 font-semibold transition duration-300 ease-in-out transform hover:scale-105"
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}


                    
                            <div className="col-lg-6 col-sm-6 col-12 p-2">
                                <div className="form-group">
                                    <label className="block text-gray-700">Use pincode to check delivery info</label>
                                    <div className="flex items-center space-x-2">
                                        <input
                                            type="text"
                                            value={pincode}
                                            onChange={(e) => setPinCode(e.target.value)}
                                            className="w-full p-2 border rounded-md"
                                            placeholder="Enter pincode"
                                        />
                                        <button onClick={handleCheckPin} className="px-4 uppercase py-2 text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300 font-semibold rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105">
                                            Submit
                                        </button>
                                    </div>
                                    {error || success ? (
                                        <p className={error ? "text-red-500 text-sm mt-2" : "text-green-600 text-sm mt-2"}>
                                            {error || success}
                                        </p>
                                    ) : null}

                                </div>
                            </div>
                        </div>
                    </div>

               
                    <aside className="lg:col-span-1 bg-white shadow-md rounded-lg px-6 py-8">
                        <h4 className="text-xl font-semibold text-gray-800 mb-6">Cart Summary</h4>
                        <div className="space-y-4">
                            <div className="flex justify-between text-gray-700">
                                <span>Total Price:</span>
                                <span>₹{totalPrice.toFixed(2)}</span>
                            </div>
                            <div className="flex justify-between text-gray-700">
                                <span>TAX:</span>
                                <span>₹{totalTax.toFixed(2)}</span>
                            </div>
                            <hr className="my-4 border-t border-gray-300" />
                            <div className="flex justify-between text-lg font-semibold text-gray-800">
                                <span>Total:</span>
                                <span>₹{(discountedPrice + totalTax).toFixed(2)}</span>
                            </div>
                        </div>

                        <div className="mt-6 justify-center">
                            <button
                                onClick={handlePlaceOrder}
                                className="w-full uppercase py-3 text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-4 focus:ring-orange-300 font-semibold rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
                            >
                                Place Order
                            </button>
                            <Link to="/" className="w-full mt-4 py-3 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105 block text-center">
                                Back to Shop
                            </Link>
                        </div>
                    </aside>
                </div>
            </div>


            {snackbarVisible && (
                <Snackbar message={snackbarMessage} visible={snackbarVisible} onClose={() => setSnackbarVisible(false)} />
            )}
        </div>
    );
};

export default CartPage;
