import ApiInstance from "../../axios/api.auth";

const findAllState = async () => {
    return await ApiInstance.get("public/state/findAll/");
};
const findAllDistrictByStateName = async (stateName) => {
    return await ApiInstance.get(`public/district/findAll/${stateName}`);
};
const findAllCityByDistrictName = async (districtName) => {
    return await ApiInstance.get(`public/city/findAll/${districtName}`);
};


const LocationServices = {
    findAllState,
    findAllDistrictByStateName,
    findAllCityByDistrictName
};

export default LocationServices;
