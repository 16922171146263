
import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SubCategoriesService from "../../../services/admin/subCategories.services";
import CategoriesService from "../../../services/admin/categories.services";
import { toast } from "react-toastify";
import { useParams, useNavigate, Link } from "react-router-dom";
import { isEmpty } from "validator";
import FileUpload from "../../../components/FileUpload/FileUpload";

const initialValues = {
  id: 0,
  categoriesId: '',
  subCategoriesName: "",
  subCategoriesDesc: "",
  attachmentId: '',
  attachmentName: ''
};

const SubCategoriesForm = () => {
  const params = useParams();
  const id = params.id;
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState(initialValues);
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_API_URL;

  const validationSchema = Yup.object({
    categoriesId: Yup.number().required("Categories is required"),
    subCategoriesName: Yup.string().required("Sub categories name is required").min(2, "Minimum 2 characters"),
    subCategoriesDesc: Yup.string().optional(),
    attachmentName: Yup.string().required("A file is required"),
  });

  const fetchCategories = useCallback(async () => {
    try {
      const response = await CategoriesService.findAllCategories();
      const result = response.data.data.rows;
      if (Array.isArray(result)) {
        setCategories(result);
      } else {
        toast.error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast.error("Failed to fetch categories");
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const handleSubmit = async (values, { resetForm }) => {
    const data = {

      categoriesId: values.categoriesId,
      subCategoriesName: values.subCategoriesName,
      subCategoriesDesc: values.subCategoriesDesc,
      attachmentId: values.attachmentId,
      attachmentName: values.attachmentName,
    };

    setLoading(true);
    try {
      if ((!isEmpty(id)) && (id > 0)) {
        const response = await SubCategoriesService.update(id, data);
        if (response.data) {
          navigate("/admin/sub-categories");
          toast.success("Subcategories updated successfully!");
        } else {
          toast.error(response.message);
        }
      } else {
        const response = await SubCategoriesService.create(data);
        if (response.data) {
          navigate("/admin/sub-categories");
          toast.success("Subcategories added successfully!");
          resetForm();
        } else {
          toast.error(response.message);
        }
      }
    } catch (error) {
      console.error("Error submitting subcategories:", error);
      toast.error(id ? "Failed to update subcategories." : "Failed to create subcategories.");
    } finally {
      setLoading(false);
    }
  };


  const fetchSubCatData = useCallback(async () => {
    try {
      if ((!isEmpty(id)) && (id > 0)) {
        const response = await SubCategoriesService.findOne(id);
        const data = response.data.data;
        console.log(data, "fetchSubCategories")
        if (data) {
          setData({
            categoriesId: data.categoriesId,
            subCategoriesName: data.subCategoriesName,
            subCategoriesDesc: data.subCategoriesDesc,
            attachmentId: data.attachmentId,
            attachmentName: data.attachmentName,
          });
        } else {
          toast.error("Failed to load Sub Categories data.");
        }
      }

    } catch (error) {
      console.error("Error fetching sub categories data:", error);
      toast.error("Failed to load sub categories data.");
    }
  }, [id]);

  useEffect(() => {
    fetchSubCatData();
  }, [fetchSubCatData]);

  const previewStyle = {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    gap: "10px",
    transition: "border-color 0.2s ease",
    borderRadius: "9px",
  };

  return (
    <div className="content">
      <div className="page-header">
        <div className="page-title">
          <h4>{(id > 0) ? "Edit Sub Category" : "Add Sub Category"}</h4>
          <h6>{(id > 0) ? "Update the subcategory details" : "Create a new subcategory"}</h6>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={data}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="row align-top">
                  {/* <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Categories: <span className="manitory">*</span></label>
                      <Field as="select" id="categoriesId" name="categoriesId" className="form-control">
                        <option value="">Choose Category</option>
                       {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.categoriesName}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="categoriesId" component="div" className="text-danger" />
                    </div>
                  </div> */}
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label htmlFor="categoriesId">Categories: <span className="manitory">*</span></label>
                      <Field as="select" name="categoriesId" className="form-control" id="categoriesId">
                        <option value="">Choose Category</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.categoriesName}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="categoriesId"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Sub-categories Name: <span className="manitory">*</span></label>
                      <Field name="subCategoriesName" className="form-control" />
                      <ErrorMessage name="subCategoriesName" component="div" className="text-danger" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Description:</label>
                      <Field as="textarea" name="subCategoriesDesc" className="form-control" placeholder="(Optional)" />
                      <ErrorMessage name="subCategoriesDesc" component="div" className="text-danger" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-12 col-12">
                    <div className="form-group">
                      <label>Sub-categories Image: <span className="manitory">*</span></label>
                      <FileUpload
                        setUploadRequest={[{
                          attachmentId: data.attachmentId,
                          attachmentName: data.attachmentName
                        }]}
                        setUploadResponse={(response) => {
                          setFieldValue('attachmentId', response[0].attachmentId);
                          setFieldValue('attachmentName', response[0].attachmentName);
                        }}
                      />
                      <ErrorMessage name="attachmentName" component="div" style={{ color: 'red' }} />
                    </div>
                  </div>

                  {(!isEmpty(id) && data.attachmentName) && (
                    <div className="col-lg-6 col-sm-12 col-12">
                      <div className="form-group">
                        <label>Old Image:</label>
                        <div style={previewStyle}>
                          <img
                            crossOrigin="anonymous"
                            src={`${BASE_URL}${data.attachmentName}`}
                            alt="Uploaded Preview"
                            style={{ maxWidth: "100%", maxHeight: "100px" }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-lg-12">
                    <button className="btn btn-submit me-2" type="submit" disabled={loading}>
                      {loading ? "Saving..." : (id > 0) ? "Update Sub Category" : "Add Sub Category"}
                    </button>
                    <Link to={"/admin/sub-categories"} className="btn btn-cancel" type="reset">
                      Cancel
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SubCategoriesForm;
