import ApiInstance from "../../axios/api.auth";
const checkPin = async (productId, pinCode) => {
  return await ApiInstance.get(`/user/check-delivery/${productId}/${pinCode}`);
};

const DeliveryService = {
  checkPin
};

export default DeliveryService;

