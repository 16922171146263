import { useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteAddress } from '../../features/addresses.slice';

const Dropdown = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  // Toggle the dropdown visibility
  const toggleDropdown = () => setIsOpen((prevState) => !prevState);

  // Handle the delete action
  const handleDelete = () => {
    dispatch(deleteAddress(id));  // Dispatch the delete action
    toast.success("Address deleted successfully!");
    navigate("/user/address"); // Redirect after delete
    setIsOpen(false); // Close the dropdown after action
  };

  return (
    <div className="relative">
      {/* Dropdown Trigger */}
      <button
        onClick={toggleDropdown}
        className="p-2 text-gray-600 hover:text-gray-900 focus:outline-none"
        aria-expanded={isOpen ? 'true' : 'false'}
      >
        <BsThreeDotsVertical />
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <ul className="absolute right-0 mt-2 w-20 bg-white border border-gray-200 rounded-md">
          <li>
            <Link
              to={`/user/address/edit/${id}`}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Edit
            </Link>
          </li>
          <li>
            <button
              onClick={handleDelete}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Delete
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
