import React, { useState, useEffect, useCallback } from "react";
import DataTable from "../../../components/Datatable/DataTable"
import BrandService from "../../../services/admin/brand.services";
import { Link } from "react-router-dom"
import DeletePopup from "../../../components/popups/DeletePopup"
import { toast } from "react-toastify";
import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";

const Brand = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const BASE_URL = process.env.REACT_APP_API_URL;

  const columns = [
    {
      field: 'id',
      headerName: 'S.NO',
      width: 90,
      renderCell: (params) => {
        const { page, pageSize } = paginationModel;
        const rowIndex = (page * pageSize) + params.api.getAllRowIds().indexOf(params.id);
        return <strong>{rowIndex + 1}</strong>; 
      }
    },
    {
      field: 'attachmentName',
      headerName: 'Brand Logo',
      width: 120,
      renderCell: (params) => {
        const imageUrl = `${BASE_URL}${params.value}?t=${new Date().getTime()}`; 
        return (
          <img
            className="product-img"
            crossOrigin="anonymous"
            src={imageUrl}
            alt=""
            style={{ width: '50px', height: '50px' }}
          />
        );
      },
    },

    { field: 'brandName', headerName: 'Brand Name', width: 200 },
    { field: 'brandCode', headerName: 'Brand Code', width: 200 },
    { field: 'brandDesc', headerName: 'Description', width: 200 },
    {
      field: 'action', headerName: 'Action', width: 200, renderCell: (params) => (
        <div className="flex mt-3">
          <Link className="me-3" to={`edit/${params.row.id}`}>
          <PencilIcon className="h-5 w-5 text-green-600"/>
          </Link>
          <Link className="me-3 confirm-text" onClick={() => handleDelete(params.row.id)}>
          <TrashIcon className="h-5 w-5 text-red-600"/>
          </Link>
        </div>
      ),
    },
  ];

  const fetchData = useCallback(async (page, pageSize, searchTerm) => {
    setLoading(true);
    try {
      const response = await BrandService.findAll(page, pageSize, searchTerm);
      setData(response.data.data.rows);
      setTotalCount(response.data.data.count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
  }, [paginationModel, fetchData, searchTerm]);

  const handleDelete = async (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await BrandService.destroy(deleteId);
      handleCloseDialog();
      toast.success("The brand has been deleted!");
      fetchData(paginationModel.page + 1, paginationModel.pageSize, searchTerm);
    } catch (error) {
      toast.error('Error deleting brand: ' + error);
      console.error('Error deleting brand:', error);
    }
  };

  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Brand List</h4>
            <h6>Manage your Brand</h6>
          </div>
          <div className="page-btn">
            <Link to={"add"} className="btn btn-added"><img src="../../../assets/img/icons/plus.svg" className="me-2" alt="img" />Add Brand</Link>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <Link className="btn btn-searchset"><img src="../../../assets/img/icons/search-white.svg" alt="img" /></Link>
                  <div id="DataTables_Table_0_filter" className="dataTables_filter"><label>
                    <input type="search" value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)} className="form-control form-control-sm" placeholder="Search..." /></label></div></div>
              </div>
            </div>
            <div className="table-responsive">
              <DataTable
                columns={columns}
                data={data}
                loading={loading}
                totalCount={totalCount}
                paginationModel={paginationModel}
                onPaginationModelChange={(model) => setPaginationModel(model)}
              />
            </div>
            <DeletePopup
              open={openDialog}
              onClose={handleCloseDialog}
              onConfirm={handleConfirmDelete}
            />
          </div>
        </div>
      </div>

    </>
  )
}

export default Brand
