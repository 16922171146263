/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link, useParams } from "react-router-dom";
import ReactImageMagnify from "@blacklab/react-image-magnify";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductById } from "./../../../features/product.slice";
import { addToCartAPI } from "../../../features/cart.slice";
import Snackbar from "../../../components/Snackbar/Snackbar";

const ProductDetailsPage = () => {
    
    const [quantity, setQuantity] = useState(1);
    const { productId } = useParams();
    const dispatch = useDispatch();
    const BASE_URL = process.env.REACT_APP_API_URL;
    const { product, loading, error } = useSelector((state) => state.products);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    useEffect(() => {
        if (productId) {
            dispatch(fetchProductById(productId));
        }
    }, [dispatch, productId]);

    const handleQuantityChange = (type) => {
        if (type === "increment") {
            setQuantity(quantity + 1);
        } else if (type === "decrement" && quantity > 1) {
            setQuantity(quantity - 1);
        }
    };



    const onAddToCart = (product) => {

        dispatch(addToCartAPI({ productId: product.id, quantity: quantity }))
            .then(() => {
                setSnackbarMessage(`${product.productName} added to cart!`);
                setSnackbarVisible(true);
                setTimeout(() => setSnackbarVisible(false), 3000);  
            })
            .catch(() => {
                setSnackbarMessage('Failed to add item to cart.');
                setSnackbarVisible(true);
                setTimeout(() => setSnackbarVisible(false), 3000);  
            });

    };

    return (
        <>
            <div className="page-wrapper ms-0">
                <div className="content">
                    {loading && (
                        <>
                           
                            <div className="flex flex-wrap lg:flex-nowrap">
                                
                                <aside className="lg:w-1/2 w-full">
                                   
                                    <div className="animate-pulse h-[30vh] lg:h-[50vh] bg-gray-200 rounded-md"></div>

                                    
                                    <div className="mt-2 lg:mt-4 h-24 space-x-2 flex overflow-x-auto">
                                        {[...Array(4)].map((_, index) => (
                                            <div
                                                key={index}
                                                className="h-16 w-16 bg-gray-200 rounded-md animate-pulse"
                                            ></div>
                                        ))}
                                    </div>
                                </aside>

                               
                                <main className="lg:w-1/2 lg:pl-8 w-full">
                                    <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200">
                                        <article>
                                            
                                            <div className="w-full h-6 bg-gray-200 rounded-md mb-3 animate-pulse"></div>

                                           
                                            <div className="flex items-center space-x-2">
                                                <div className="w-24 h-4 bg-gray-200 rounded-md animate-pulse"></div>
                                            </div>

                                           
                                            <div className="w-32 h-6 bg-gray-200 rounded-md mt-2 mb-3 animate-pulse"></div>

                                            
                                            <div className="w-full h-4 bg-gray-200 rounded-md mb-3 animate-pulse"></div>

                                           
                                            {[...Array(4)].map((_, index) => (
                                                <div
                                                    key={index}
                                                    className="w-full h-4 bg-gray-200 rounded-md mb-2 animate-pulse"
                                                ></div>
                                            ))}

                                           
                                            <div className="flex space-x-3 mt-4">
                                                <div className="w-24 h-8 bg-gray-200 rounded-md animate-pulse"></div>
                                                <div className="w-24 h-8 bg-gray-200 rounded-md animate-pulse"></div>
                                            </div>
                                        </article>
                                    </div>
                                </main>
                            </div>
                        </>
                    )}

                    
                    {error && (
                        <div className="flex space-x-3 justify-center">
                            <p>Error: {error}</p>
                            {error.includes("404") && (
                                <p>The products you are looking for are not available.</p>
                            )}
                        </div>
                    )}

                    {product && Object.keys(product).length > 0 ? (
                        <>
                            <div className="flex flex-wrap lg:flex-nowrap">
                                
                                <aside className="lg:w-1/2 w-full">
                                   

                                    <Swiper
                                        spaceBetween={5}
                                        slidesPerView={1}
                                        loop={true}
                                        className="h-[30vh] lg:h-[50vh]" 
                                    >
                                        <SwiperSlide>
                                            <div className="rounded-md overflow-hidden">
                                                <ReactImageMagnify
                                                    imageProps={{
                                                        crossOrigin: "anonymous",
                                                        alt: "Product Image",
                                                        src: `${BASE_URL}${product.attachmentName}`,
                                                        width: 390,
                                                        height: 340,
                                                    }}
                                                    magnifyContainerProps={{
                                                        scale: 1.5, 
                                                    }}
                                                    magnifiedImageProps={{
                                                        crossOrigin: "anonymous",
                                                        height: 800,
                                                        src: `${BASE_URL}${product.attachmentName}`,
                                                        width: 800,
                                                    }}
                                                    portalProps={{
                                                        horizontalOffset: 0,
                                                    }}
                                                />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>

                                   
                                    <Swiper
                                        spaceBetween={5}
                                        slidesPerView={4}
                                        loop={true}
                                        className="thumbs-wrap mt-2 lg:mt-4 h-24"
                                        breakpoints={{
                                            1024: { slidesPerView: 4 },
                                            768: { slidesPerView: 3 },
                                            480: { slidesPerView: 2 },
                                            319: { slidesPerView: 1 },
                                        }}
                                    >
                                        
                                        <SwiperSlide>
                                            <a
                                                href="#"
                                                className="item-thumb"
                                         
                                            >
                                                <div className="rounded-md overflow-hidden">
                                                    <img
                                                        crossOrigin="anonymous"
                                                        src={`${BASE_URL}${product.attachmentName}`}
                                                        alt={`Thumbnail`}
                                                        className="w-16 h-16 object-cover rounded-md"
                                                    />
                                                </div>
                                            </a>
                                        </SwiperSlide>
                                     
                                    </Swiper>
                                </aside>

                             
                                <main className="lg:w-1/2 lg:pl-8 w-full">
                                    <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200">
                                        <article>
                                            <h4 className="text-xl font-semibold text-gray-900 leading-tight mb-3">
                                                {product.productName}
                                            </h4>

                                           
                                            <div className="rating-wrap my-2 flex items-center space-x-2">
                                                <ul className="rating-stars flex space-x-1">
                                                    {[...Array(4)].map((_, index) => (
                                                        <li key={index} className="text-yellow-400">
                                                            ★
                                                        </li>
                                                    ))}
                                                    <li className="text-gray-300">★</li>
                                                </ul>
                                                <b className="text-gray-900">4.5</b>
                                                <span className="text-gray-500">154 orders</span>
                                                <span>
                                                    {product.stock !== 0 ? (
                                                        <p className="text-green-500">In Stock</p>
                                                    ) : (
                                                        <p className="text-red-500">Out of Stock</p>
                                                    )}
                                                </span>
                                            </div>

                                           
                                            <div className="mb-2">
                                                <var className="text-xl font-bold text-gray-900">
                                                    {" "}
                                                    ₹{product.price}{" "}
                                                </var>
                                                <span className="text-xs text-gray-500">
                                                    /{product.unit_master.unitType}
                                                </span>
                                            </div>

                                           
                                            <p className="text-gray-700 text-sm mb-3">
                                                {product.description}
                                            </p>

                                     
                                            <div className="mt-3 text-sm">
                                                <div className="flex justify-between text-gray-600">
                                                    <span>Type:</span>
                                                    <span>Regular</span>
                                                </div>
                                                <div className="flex justify-between text-gray-600">
                                                    <span>Color:</span>
                                                    <span>Brown</span>
                                                </div>
                                                <div className="flex justify-between text-gray-600">
                                                    <span>Material:</span>
                                                    <span>Cotton, Jeans</span>
                                                </div>
                                                <div className="flex justify-between text-gray-600">
                                                    <span>Brand:</span>
                                                    <span>Reebok</span>
                                                </div>
                                            </div>

                                            <hr className="my-1" />
                                          
                                            <div className="flex flex-wrap mb-2">
                                                <div className="w-1/2 md:w-1/3 mb-3">
                                                    <label className="block text-gray-700 text-sm mb-3">
                                                        Quantity:{" "}
                                                    </label>
                                                    <div className="flex items-center gap-3">
                                                        <button
                                                            className="btn btn-light bg-gray-200 p-2 rounded-full"
                                                            type="button"
                                                            onClick={() => handleQuantityChange("decrement")}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={18}
                                                                height={18}
                                                                fill="#999"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path d="M19 13H5v-2h14v2z" />
                                                            </svg>
                                                        </button>
                                                        <input
                                                            className="w-10 text-center border border-gray-300 rounded-md"
                                                            type="text"
                                                            value={quantity}
                                                            readOnly
                                                        />
                                                        <button
                                                            className="btn btn-light bg-gray-200 p-2 rounded-full"
                                                            type="button"
                                                            onClick={() => handleQuantityChange("increment")}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={18}
                                                                height={18}
                                                                fill="#999"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                         
                                            <div className="flex space-x-3">
                                                <Link
                                                    to="/"
                                                    className=" btn shadow-sm  text-sm py-2 px-4 border border-gray-300 text-orange-500 rounded-lg hover:bg-gray-100 transition duration-200 block text-center"
                                                >
                                                    Back to Shop
                                                </Link>
                                                <Link onClick={() => onAddToCart(product)}
                                                    className="btn bg-blue-600 text-white py-2 px-4 rounded-md shadow-sm flex items-center hover:bg-blue-700 transition-colors text-sm"
                                                >
                                                    <i className="me-1 fa fa-shopping-basket" /> Add to
                                                    Cart
                                                </Link>
                                            </div>
                                        </article>
                                    </div>
                                </main>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex space-x-3 justify-center">
                                Product not found
                            </div>
                        </>
                    )}
                </div>
            </div>
            {snackbarVisible && (
                <Snackbar message={snackbarMessage} visible={snackbarVisible} onClose={() => setSnackbarVisible(false)} />
            )}
        </>
    );
};

export default ProductDetailsPage;

